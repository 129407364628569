export const data =  {
    "nominal": [
        {"Category": "Entertainment", "exponent": 11, "mantissa": 1.04, "exponent+mantissa": 11.0},
        {"Category": "Gaming", "exponent": 9, "mantissa": 7.73, "exponent+mantissa": 9.75},
        {"Category": "How to & Style", "exponent": 9, "mantissa": 9.77, "exponent+mantissa": 9.97},
        {"Category": "Movies", "exponent": 7, "mantissa": 7.03, "exponent+mantissa": 7.67},
        {"Category": "Music", "exponent": 11, "mantissa": 2.55, "exponent+mantissa": 11.17},
        {"Category": "News & Politics", "exponent": 10, "mantissa": 1.04, "exponent+mantissa": 10.0},
        {"Category": "Trailers", "exponent": 4, "mantissa": 5.5, "exponent+mantissa": 4.5}
    ],
    "ordinal": [
        {"Sentiment": "7-Very Positive", "exponent": 8, "mantissa": 9.59, "exponent+mantissa": 8.95},
        {"Sentiment": "6-Positive", "exponent": 8, "mantissa": 2.86, "exponent+mantissa": 8.21},
        {"Sentiment": "5-Partially Positive", "exponent": 6, "mantissa": 9.65, "exponent+mantissa": 6.96},
        {"Sentiment": "4-Neutral", "exponent": 5, "mantissa": 6.6, "exponent+mantissa": 5.62},
        {"Sentiment": "3-Partially Negative", "exponent": 7, "mantissa": 1.06, "exponent+mantissa": 7.01},
        {"Sentiment": "2-Negative", "exponent": 8, "mantissa": 7.55, "exponent+mantissa": 8.73},
        {"Sentiment": "1-Very Negative", "exponent": 9, "mantissa": 3, "exponent+mantissa": 9.22}
    ],
    "quantitative": [
        {"LikesPerComment": 9.68, "exponent": 4, "mantissa": 2.9, "exponent+mantissa": 4.21},
        {"LikesPerComment": 11.88, "exponent": 5, "mantissa": 1.49, "exponent+mantissa": 5.05},
        { "LikesPerComment": 6.68, "exponent": 5, "mantissa": 9.87, "exponent+mantissa": 5.99},
        {"LikesPerComment": 16, "exponent": 6, "mantissa": 6.98, "exponent+mantissa": 6.66},
        {"LikesPerComment": 15, "exponent": 8, "mantissa": 4, "exponent+mantissa": 8.33},
        {"LikesPerComment": 13.09, "exponent": 9, "mantissa": 1, "exponent+mantissa": 9.0},
        {"LikesPerComment": 12.27, "exponent": 9, "mantissa": 1.87, "exponent+mantissa": 9.1}
    ],
    "date": [
        {"Date": "2018-01-22T12:00:00.000Z", "exponent": 3, "mantissa": 2.97, "exponent+mantissa": 3.22},
        {"Date": "2018-01-23T12:00:00.000Z", "exponent": 4, "mantissa": 3.56, "exponent+mantissa": 4.28},
        {"Date": "2018-01-24T12:00:00.000Z", "exponent": 4, "mantissa": 3.43, "exponent+mantissa": 4.27},
        {"Date": "2018-01-25T12:00:00.000Z", "exponent": 6, "mantissa": 8.07, "exponent+mantissa": 6.79},
        {"Date": "2018-01-26T12:00:00.000Z", "exponent": 7, "mantissa": 1.58, "exponent+mantissa": 7.06},
        {"Date": "2018-01-27T12:00:00.000Z", "exponent": 7, "mantissa": 1.9, "exponent+mantissa": 7.1},
        {"Date": "2018-01-28T12:00:00.000Z", "exponent": 3, "mantissa": 9.29, "exponent+mantissa": 3.92},
        {"Date": "2018-01-29T12:00:00.000Z", "exponent": 0, "mantissa": 0, "exponent+mantissa": 0},
    ]
  }


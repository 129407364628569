/* eslint-disable require-jsdoc */
/* eslint-disable */
// React imports
import React, {useState} from 'react';

// Data
import {combinations} from './combinationsArray.js'
import PlotComponent from './components/PlotComponent.js';
import AreaLegend from './components/AreaLegend.js';
import LengthLegend from './components/LengthLegend.js';
import WidthLegend from './components/WidthLegend.js';
import WidthBarLegend from './components/WidthBarLegend.js';

import {data} from './data.js'
import {columns} from './columns.js'


// Style
import './App.scss';

export default function App() {
  const digitsMantissa = 1;
  const [filterDataset, setFilterDataset] = useState("all");
  const [filterMark, setFilterMark] = useState("all");
  const [filterChannel, setFilterChannel] = useState("all");
  const [filterEffectiveness, setFilterEffectiveness] = useState("all");
  const [error, setError] = useState(Error());

  const EffortlessAndEffective = [9,261,391,159,141,482,1,253,383,151,137,369,479,2,254,384,152,306,436,102,122,110,130,54,373];
  const SuitableWithEffort = [491, 101, 30, 109, 129, 148, 121];
  const MediumEffort = [104,356,3,255,385,153,4,256,386,154,139,371,480,492,124,103,355,138,370,353,354,112,364,56,308,438,11,263,393,32,131,57,309,439,12,264,394,162,33,132,11,363,55,307,437,10,262,392,160,31,53,305,435,362,361];
  const HighEffort = [18,270,400,168,17,167,116,368,252,16,268,398,166,134,21,273,403,171,20,402,170,212,19,401,169,143,375,483,495,136,14,396,164,15,397,165,366,250,13,265,395,163,142,374,240,123,220,200,239,217,237,197,238,198,494,248,207,161,228,208,247,225,245,226,246,205,206,210,218,219,227,230,232,399,199,114,266,267,269,271,272];
  
  const effectiveness = {'Effective assignments':EffortlessAndEffective, 'Value retrieval, but no comparisons':SuitableWithEffort, 'Magnitude overview, but no detail':MediumEffort, 'Impractical for OMVs':HighEffort}

    const handleFilter = (e) => {
      const { name, value } = e.target;
      if(name === 'dataset'){
        setFilterDataset(value)
      }
      if(name === 'mark'){
        setFilterMark(value)
      }
      if(name === 'channel'){
        setFilterChannel(value)
      }
      if(name === 'effectiveness'){
        setFilterEffectiveness(value)
      }
    }


    const filterCombos = (c) => {
      return c.filter(comb => {
        // Check dataset type
        if (filterDataset !== "all" && comb.dataset.type !== filterDataset) {
          return false;
        }
    
        // Check mark
        if (filterMark !== "all" && comb.mark !== filterMark) {
          return false;
        }
    
        // Check visual variable channel
        if (filterChannel !== "all" && comb.visualVariables[filterChannel] === null) {
          return false;
        }

        if (filterEffectiveness !== "all" && !effectiveness[filterEffectiveness].includes(comb.id)) {
          return false;
        }
        // If none of the conditions block the item, it passes the filter
        return true;
      });
    };


  return (
<div className="main">
      <div>
      <span><b>Dataset:</b>   
          <select name='dataset' value={filterDataset} onChange={handleFilter}>
              <option value="all">All datasets</option>
              {['nominal', 'ordinal', 'date', 'quantitative'].map(option => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
        </span>&nbsp;&nbsp;&nbsp;
        <span><b>Mark:</b>   
          <select name='mark' value={filterMark} onChange={handleFilter}>
              <option value="all">All marks</option>
              {['point', 'line', 'area-line'].map(option => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
        </span>&nbsp;&nbsp;&nbsp; 
        <span><b>Visual variable:</b>   
          <select name='channel' value={filterChannel} onChange={handleFilter}>
              <option value="all">All channels</option>
              {['positionX', 'positionY', 'column', 'row', 'length', 'area', 'colorIntensity', 'colorHue', 'shape'].map(option => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
        </span>&nbsp;&nbsp;&nbsp; 
        <span><b>Evaluation categories:</b>   
          <select name='effectiveness' value={filterEffectiveness} onChange={handleFilter}>
              <option value="all">All categories</option>
              {['Effective assignments', 'Value retrieval, but no comparisons', 'Magnitude overview, but no detail', 'Impractical for OMVs'].map(option => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
        </span>&nbsp;&nbsp;&nbsp; 
      </div>
      <>
        <div className="plots-container">
          {filterCombos(combinations).map(comb => (
              <div className="plot" key={comb.id}>
                <span className="desc">
                  <p><b>ID:</b><span className="plotId">{comb.id}</span>, <b>Data:</b> {comb.dataset.type}, <b>Point:</b> {comb.mark}</p>
                  <p><b>Visual Variables:</b>
                  {Object.entries(comb.visualVariables).map(([key,value]) => (
                    value !== null &&
                    <span key={key}> {key} = {value}, </span>
                  ))}
                  </p>
                </span>
                {(comb.visualVariables.area && !comb.visualVariables.length) && 
                  <AreaLegend
                    data={data[comb.dataset.type]}
                    visualVariables={comb.visualVariables}
                    mark={comb.mark}
                    /> 
                }
                {(comb.visualVariables.area && comb.visualVariables.length) && 
                  <WidthLegend
                    data={data[comb.dataset.type]}
                    visualVariables={comb.visualVariables}
                    mark={comb.mark}
                    /> 
                }
                {(comb.visualVariables.length && comb.mark !== 'line') && 
                  <LengthLegend
                    data={data[comb.dataset.type]}
                    visualVariables={comb.visualVariables}
                    mark={comb.mark}
                    /> 
                }
                 {(comb.visualVariables.length && comb.mark !== 'point') && 
                  <WidthBarLegend
                    data={data[comb.dataset.type]}
                    visualVariables={comb.visualVariables}
                    mark={comb.mark}
                    /> 
                }
                <PlotComponent
                  data={data[comb.dataset.type]}
                  selectedColumns={columns[comb.dataset.type]}
                  visualVariables={comb.visualVariables}
                  digits={digitsMantissa}
                  mark={comb.mark}
                  /> 
              </div>
          ))}
          
        </div>
      </>
    </div>
  );
}

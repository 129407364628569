export const combinations = [
  {
    'id': 1,
    'dataset': {
      'dataset': 'nominalSmall',
      'attribute': 'Category',
      'type': 'nominal',
      'range': 7,
    },
    'selectedColumns': [
      'mantissa',
      'exponent',
      'Category',
    ],
    'mark': 'point',
    'visualVariables': {
      'positionX': 'Category',
      'positionY': 'exponent+mantissa',
      'column': null,
      'row': null,
      'length': null,
      'area': null,
      'colorIntensity': null,
      'colorHue': null,
      'shape': null,
    },
  },
  {
    'id': 2,
    'dataset': {
      'dataset': 'nominalSmall',
      'attribute': 'Category',
      'type': 'nominal',
      'range': 7,
    },
    'selectedColumns': [
      'mantissa',
      'exponent',
      'Category',
    ],
    'mark': 'point',
    'visualVariables': {
      'positionX': 'Category',
      'positionY': 'exponent',
      'column': null,
      'row': null,
      'length': 'mantissa',
      'area': null,
      'colorIntensity': null,
      'colorHue': null,
      'shape': null,
    },
  },
  {
    'id': 3,
    'dataset': {
      'dataset': 'nominalSmall',
      'attribute': 'Category',
      'type': 'nominal',
      'range': 7,
    },
    'selectedColumns': [
      'mantissa',
      'exponent',
      'Category',
    ],
    'mark': 'point',
    'visualVariables': {
      'positionX': 'Category',
      'positionY': 'exponent',
      'column': null,
      'row': null,
      'length': null,
      'area': 'mantissa',
      'colorIntensity': null,
      'colorHue': null,
      'shape': null,
    },
  },
  {
    'id': 4,
    'dataset': {
      'dataset': 'nominalSmall',
      'attribute': 'Category',
      'type': 'nominal',
      'range': 7,
    },
    'selectedColumns': [
      'mantissa',
      'exponent',
      'Category',
    ],
    'mark': 'point',
    'visualVariables': {
      'positionX': 'Category',
      'positionY': 'exponent',
      'column': null,
      'row': null,
      'length': null,
      'area': null,
      'colorIntensity': 'mantissa',
      'colorHue': null,
      'shape': null,
    },
  },
  {
    'id': 9,
    'dataset': {
      'dataset': 'nominalSmall',
      'attribute': 'Category',
      'type': 'nominal',
      'range': 7,
    },
    'selectedColumns': [
      'mantissa',
      'exponent',
      'Category',
    ],
    'mark': 'point',
    'visualVariables': {
      'positionX': 'Category',
      'positionY': 'mantissa',
      'column': null,
      'row': 'exponent',
      'length': null,
      'area': null,
      'colorIntensity': null,
      'colorHue': null,
      'shape': null,
    },
  },
  {
    'id': 10,
    'dataset': {
      'dataset': 'nominalSmall',
      'attribute': 'Category',
      'type': 'nominal',
      'range': 7,
    },
    'selectedColumns': [
      'mantissa',
      'exponent',
      'Category',
    ],
    'mark': 'point',
    'visualVariables': {
      'positionX': 'Category',
      'positionY': null,
      'column': null,
      'row': 'exponent',
      'length': 'mantissa',
      'area': null,
      'colorIntensity': null,
      'colorHue': null,
      'shape': null,
    },
  },
  {
    'id': 11,
    'dataset': {
      'dataset': 'nominalSmall',
      'attribute': 'Category',
      'type': 'nominal',
      'range': 7,
    },
    'selectedColumns': [
      'mantissa',
      'exponent',
      'Category',
    ],
    'mark': 'point',
    'visualVariables': {
      'positionX': 'Category',
      'positionY': null,
      'column': null,
      'row': 'exponent',
      'length': null,
      'area': 'mantissa',
      'colorIntensity': null,
      'colorHue': null,
      'shape': null,
    },
  },
  {
    'id': 12,
    'dataset': {
      'dataset': 'nominalSmall',
      'attribute': 'Category',
      'type': 'nominal',
      'range': 7,
    },
    'selectedColumns': [
      'mantissa',
      'exponent',
      'Category',
    ],
    'mark': 'point',
    'visualVariables': {
      'positionX': 'Category',
      'positionY': null,
      'column': null,
      'row': 'exponent',
      'length': null,
      'area': null,
      'colorIntensity': 'mantissa',
      'colorHue': null,
      'shape': null,
    },
  },
  {
    'id': 13,
    'dataset': {
      'dataset': 'nominalSmall',
      'attribute': 'Category',
      'type': 'nominal',
      'range': 7,
    },
    'selectedColumns': [
      'mantissa',
      'exponent',
      'Category',
    ],
    'mark': 'point',
    'visualVariables': {
      'positionX': 'Category',
      'positionY': 'mantissa',
      'column': null,
      'row': null,
      'length': 'exponent',
      'area': null,
      'colorIntensity': null,
      'colorHue': null,
      'shape': null,
    },
  },
  {
    'id': 14,
    'dataset': {
      'dataset': 'nominalSmall',
      'attribute': 'Category',
      'type': 'nominal',
      'range': 7,
    },
    'selectedColumns': [
      'mantissa',
      'exponent',
      'Category',
    ],
    'mark': 'point',
    'visualVariables': {
      'positionX': 'Category',
      'positionY': null,
      'column': null,
      'row': null,
      'length': 'exponent',
      'area': 'mantissa',
      'colorIntensity': null,
      'colorHue': null,
      'shape': null,
    },
  },
  {
    'id': 15,
    'dataset': {
      'dataset': 'nominalSmall',
      'attribute': 'Category',
      'type': 'nominal',
      'range': 7,
    },
    'selectedColumns': [
      'mantissa',
      'exponent',
      'Category',
    ],
    'mark': 'point',
    'visualVariables': {
      'positionX': 'Category',
      'positionY': null,
      'column': null,
      'row': null,
      'length': 'exponent',
      'area': null,
      'colorIntensity': 'mantissa',
      'colorHue': null,
      'shape': null,
    },
  },
  {
    'id': 16,
    'dataset': {
      'dataset': 'nominalSmall',
      'attribute': 'Category',
      'type': 'nominal',
      'range': 7,
    },
    'selectedColumns': [
      'mantissa',
      'exponent',
      'Category',
    ],
    'mark': 'point',
    'visualVariables': {
      'positionX': 'Category',
      'positionY': 'mantissa',
      'column': null,
      'row': null,
      'length': null,
      'area': 'exponent',
      'colorIntensity': null,
      'colorHue': null,
      'shape': null,
    },
  },
  {
    'id': 17,
    'dataset': {
      'dataset': 'nominalSmall',
      'attribute': 'Category',
      'type': 'nominal',
      'range': 7,
    },
    'selectedColumns': [
      'mantissa',
      'exponent',
      'Category',
    ],
    'mark': 'point',
    'visualVariables': {
      'positionX': 'Category',
      'positionY': null,
      'column': null,
      'row': null,
      'length': 'mantissa',
      'area': 'exponent',
      'colorIntensity': null,
      'colorHue': null,
      'shape': null,
    },
  },
  {
    'id': 18,
    'dataset': {
      'dataset': 'nominalSmall',
      'attribute': 'Category',
      'type': 'nominal',
      'range': 7,
    },
    'selectedColumns': [
      'mantissa',
      'exponent',
      'Category',
    ],
    'mark': 'point',
    'visualVariables': {
      'positionX': 'Category',
      'positionY': null,
      'column': null,
      'row': null,
      'length': null,
      'area': 'exponent',
      'colorIntensity': 'mantissa',
      'colorHue': null,
      'shape': null,
    },
  },
  {
    'id': 19,
    'dataset': {
      'dataset': 'nominalSmall',
      'attribute': 'Category',
      'type': 'nominal',
      'range': 7,
    },
    'selectedColumns': [
      'mantissa',
      'exponent',
      'Category',
    ],
    'mark': 'point',
    'visualVariables': {
      'positionX': 'Category',
      'positionY': 'mantissa',
      'column': null,
      'row': null,
      'length': null,
      'area': null,
      'colorIntensity': 'exponent',
      'colorHue': null,
      'shape': null,
    },
  },
  {
    'id': 20,
    'dataset': {
      'dataset': 'nominalSmall',
      'attribute': 'Category',
      'type': 'nominal',
      'range': 7,
    },
    'selectedColumns': [
      'mantissa',
      'exponent',
      'Category',
    ],
    'mark': 'point',
    'visualVariables': {
      'positionX': 'Category',
      'positionY': null,
      'column': null,
      'row': null,
      'length': 'mantissa',
      'area': null,
      'colorIntensity': 'exponent',
      'colorHue': null,
      'shape': null,
    },
  },
  {
    'id': 21,
    'dataset': {
      'dataset': 'nominalSmall',
      'attribute': 'Category',
      'type': 'nominal',
      'range': 7,
    },
    'selectedColumns': [
      'mantissa',
      'exponent',
      'Category',
    ],
    'mark': 'point',
    'visualVariables': {
      'positionX': 'Category',
      'positionY': null,
      'column': null,
      'row': null,
      'length': null,
      'area': 'mantissa',
      'colorIntensity': 'exponent',
      'colorHue': null,
      'shape': null,
    },
  },
  {
    'id': 30,
    'dataset': {
      'dataset': 'nominalSmall',
      'attribute': 'Category',
      'type': 'nominal',
      'range': 7,
    },
    'selectedColumns': [
      'mantissa',
      'exponent',
      'Category',
    ],
    'mark': 'point',
    'visualVariables': {
      'positionX': 'mantissa',
      'positionY': 'Category',
      'column': null,
      'row': 'exponent',
      'length': null,
      'area': null,
      'colorIntensity': null,
      'colorHue': null,
      'shape': null,
    },
  },
  {
    'id': 31,
    'dataset': {
      'dataset': 'nominalSmall',
      'attribute': 'Category',
      'type': 'nominal',
      'range': 7,
    },
    'selectedColumns': [
      'mantissa',
      'exponent',
      'Category',
    ],
    'mark': 'point',
    'visualVariables': {
      'positionX': null,
      'positionY': 'Category',
      'column': null,
      'row': 'exponent',
      'length': 'mantissa',
      'area': null,
      'colorIntensity': null,
      'colorHue': null,
      'shape': null,
    },
  },
  {
    'id': 32,
    'dataset': {
      'dataset': 'nominalSmall',
      'attribute': 'Category',
      'type': 'nominal',
      'range': 7,
    },
    'selectedColumns': [
      'mantissa',
      'exponent',
      'Category',
    ],
    'mark': 'point',
    'visualVariables': {
      'positionX': null,
      'positionY': 'Category',
      'column': null,
      'row': 'exponent',
      'length': null,
      'area': 'mantissa',
      'colorIntensity': null,
      'colorHue': null,
      'shape': null,
    },
  },
  {
    'id': 33,
    'dataset': {
      'dataset': 'nominalSmall',
      'attribute': 'Category',
      'type': 'nominal',
      'range': 7,
    },
    'selectedColumns': [
      'mantissa',
      'exponent',
      'Category',
    ],
    'mark': 'point',
    'visualVariables': {
      'positionX': null,
      'positionY': 'Category',
      'column': null,
      'row': 'exponent',
      'length': null,
      'area': null,
      'colorIntensity': 'mantissa',
      'colorHue': null,
      'shape': null,
    },
  },
  {
    'id': 53,
    'dataset': {
      'dataset': 'nominalSmall',
      'attribute': 'Category',
      'type': 'nominal',
      'range': 7,
    },
    'selectedColumns': [
      'mantissa',
      'exponent',
      'Category',
    ],
    'mark': 'point',
    'visualVariables': {
      'positionX': 'mantissa',
      'positionY': null,
      'column': 'Category',
      'row': 'exponent',
      'length': null,
      'area': null,
      'colorIntensity': null,
      'colorHue': null,
      'shape': null,
    },
  },
  {
    'id': 54,
    'dataset': {
      'dataset': 'nominalSmall',
      'attribute': 'Category',
      'type': 'nominal',
      'range': 7,
    },
    'selectedColumns': [
      'mantissa',
      'exponent',
      'Category',
    ],
    'mark': 'point',
    'visualVariables': {
      'positionX': null,
      'positionY': 'mantissa',
      'column': 'Category',
      'row': 'exponent',
      'length': null,
      'area': null,
      'colorIntensity': null,
      'colorHue': null,
      'shape': null,
    },
  },
  {
    'id': 55,
    'dataset': {
      'dataset': 'nominalSmall',
      'attribute': 'Category',
      'type': 'nominal',
      'range': 7,
    },
    'selectedColumns': [
      'mantissa',
      'exponent',
      'Category',
    ],
    'mark': 'point',
    'visualVariables': {
      'positionX': null,
      'positionY': null,
      'column': 'Category',
      'row': 'exponent',
      'length': 'mantissa',
      'area': null,
      'colorIntensity': null,
      'colorHue': null,
      'shape': null,
    },
  },
  {
    'id': 56,
    'dataset': {
      'dataset': 'nominalSmall',
      'attribute': 'Category',
      'type': 'nominal',
      'range': 7,
    },
    'selectedColumns': [
      'mantissa',
      'exponent',
      'Category',
    ],
    'mark': 'point',
    'visualVariables': {
      'positionX': null,
      'positionY': null,
      'column': 'Category',
      'row': 'exponent',
      'length': null,
      'area': 'mantissa',
      'colorIntensity': null,
      'colorHue': null,
      'shape': null,
    },
  },
  {
    'id': 57,
    'dataset': {
      'dataset': 'nominalSmall',
      'attribute': 'Category',
      'type': 'nominal',
      'range': 7,
    },
    'selectedColumns': [
      'mantissa',
      'exponent',
      'Category',
    ],
    'mark': 'point',
    'visualVariables': {
      'positionX': null,
      'positionY': null,
      'column': 'Category',
      'row': 'exponent',
      'length': null,
      'area': null,
      'colorIntensity': 'mantissa',
      'colorHue': null,
      'shape': null,
    },
  },
  {
    'id': 101,
    'dataset': {
      'dataset': 'nominalSmall',
      'attribute': 'Category',
      'type': 'nominal',
      'range': 7,
    },
    'selectedColumns': [
      'mantissa',
      'exponent',
      'Category',
    ],
    'mark': 'point',
    'visualVariables': {
      'positionX': 'mantissa',
      'positionY': 'exponent',
      'column': null,
      'row': null,
      'length': null,
      'area': null,
      'colorIntensity': null,
      'colorHue': 'Category',
      'shape': null,
    },
  },
  {
    'id': 102,
    'dataset': {
      'dataset': 'nominalSmall',
      'attribute': 'Category',
      'type': 'nominal',
      'range': 7,
    },
    'selectedColumns': [
      'mantissa',
      'exponent',
      'Category',
    ],
    'mark': 'point',
    'visualVariables': {
      'positionX': null,
      'positionY': 'exponent+mantissa',
      'column': null,
      'row': null,
      'length': null,
      'area': null,
      'colorIntensity': null,
      'colorHue': 'Category',
      'shape': null,
    },
  },
  {
    'id': 103,
    'dataset': {
      'dataset': 'nominalSmall',
      'attribute': 'Category',
      'type': 'nominal',
      'range': 7,
    },
    'selectedColumns': [
      'mantissa',
      'exponent',
      'Category',
    ],
    'mark': 'point',
    'visualVariables': {
      'positionX': null,
      'positionY': 'exponent',
      'column': null,
      'row': null,
      'length': 'mantissa',
      'area': null,
      'colorIntensity': null,
      'colorHue': 'Category',
      'shape': null,
    },
  },
  {
    'id': 104,
    'dataset': {
      'dataset': 'nominalSmall',
      'attribute': 'Category',
      'type': 'nominal',
      'range': 7,
    },
    'selectedColumns': [
      'mantissa',
      'exponent',
      'Category',
    ],
    'mark': 'point',
    'visualVariables': {
      'positionX': null,
      'positionY': 'exponent',
      'column': null,
      'row': null,
      'length': null,
      'area': 'mantissa',
      'colorIntensity': null,
      'colorHue': 'Category',
      'shape': null,
    },
  },
  {
    'id': 109,
    'dataset': {
      'dataset': 'nominalSmall',
      'attribute': 'Category',
      'type': 'nominal',
      'range': 7,
    },
    'selectedColumns': [
      'mantissa',
      'exponent',
      'Category',
    ],
    'mark': 'point',
    'visualVariables': {
      'positionX': 'mantissa',
      'positionY': null,
      'column': null,
      'row': 'exponent',
      'length': null,
      'area': null,
      'colorIntensity': null,
      'colorHue': 'Category',
      'shape': null,
    },
  },
  {
    'id': 110,
    'dataset': {
      'dataset': 'nominalSmall',
      'attribute': 'Category',
      'type': 'nominal',
      'range': 7,
    },
    'selectedColumns': [
      'mantissa',
      'exponent',
      'Category',
    ],
    'mark': 'point',
    'visualVariables': {
      'positionX': null,
      'positionY': 'mantissa',
      'column': null,
      'row': 'exponent',
      'length': null,
      'area': null,
      'colorIntensity': null,
      'colorHue': 'Category',
      'shape': null,
    },
  },
  {
    'id': 111,
    'dataset': {
      'dataset': 'nominalSmall',
      'attribute': 'Category',
      'type': 'nominal',
      'range': 7,
    },
    'selectedColumns': [
      'mantissa',
      'exponent',
      'Category',
    ],
    'mark': 'point',
    'visualVariables': {
      'positionX': null,
      'positionY': null,
      'column': null,
      'row': 'exponent',
      'length': 'mantissa',
      'area': null,
      'colorIntensity': null,
      'colorHue': 'Category',
      'shape': null,
    },
  },
  {
    'id': 112,
    'dataset': {
      'dataset': 'nominalSmall',
      'attribute': 'Category',
      'type': 'nominal',
      'range': 7,
    },
    'selectedColumns': [
      'mantissa',
      'exponent',
      'Category',
    ],
    'mark': 'point',
    'visualVariables': {
      'positionX': null,
      'positionY': null,
      'column': null,
      'row': 'exponent',
      'length': null,
      'area': 'mantissa',
      'colorIntensity': null,
      'colorHue': 'Category',
      'shape': null,
    },
  },
  {
    'id': 114,
    'dataset': {
      'dataset': 'nominalSmall',
      'attribute': 'Category',
      'type': 'nominal',
      'range': 7,
    },
    'selectedColumns': [
      'mantissa',
      'exponent',
      'Category',
    ],
    'mark': 'point',
    'visualVariables': {
      'positionX': null,
      'positionY': 'mantissa',
      'column': null,
      'row': null,
      'length': 'exponent',
      'area': null,
      'colorIntensity': null,
      'colorHue': 'Category',
      'shape': null,
    },
  },
  {
    'id': 116,
    'dataset': {
      'dataset': 'nominalSmall',
      'attribute': 'Category',
      'type': 'nominal',
      'range': 7,
    },
    'selectedColumns': [
      'mantissa',
      'exponent',
      'Category',
    ],
    'mark': 'point',
    'visualVariables': {
      'positionX': null,
      'positionY': 'mantissa',
      'column': null,
      'row': null,
      'length': null,
      'area': 'exponent',
      'colorIntensity': null,
      'colorHue': 'Category',
      'shape': null,
    },
  },
  {
    'id': 121,
    'dataset': {
      'dataset': 'nominalSmall',
      'attribute': 'Category',
      'type': 'nominal',
      'range': 7,
    },
    'selectedColumns': [
      'mantissa',
      'exponent',
      'Category',
    ],
    'mark': 'point',
    'visualVariables': {
      'positionX': 'mantissa',
      'positionY': 'exponent',
      'column': null,
      'row': null,
      'length': null,
      'area': null,
      'colorIntensity': null,
      'colorHue': null,
      'shape': 'Category',
    },
  },
  {
    'id': 122,
    'dataset': {
      'dataset': 'nominalSmall',
      'attribute': 'Category',
      'type': 'nominal',
      'range': 7,
    },
    'selectedColumns': [
      'mantissa',
      'exponent',
      'Category',
    ],
    'mark': 'point',
    'visualVariables': {
      'positionX': null,
      'positionY': 'exponent+mantissa',
      'column': null,
      'row': null,
      'length': null,
      'area': null,
      'colorIntensity': null,
      'colorHue': null,
      'shape': 'Category',
    },
  },
  {
    'id': 123,
    'dataset': {
      'dataset': 'nominalSmall',
      'attribute': 'Category',
      'type': 'nominal',
      'range': 7,
    },
    'selectedColumns': [
      'mantissa',
      'exponent',
      'Category',
    ],
    'mark': 'point',
    'visualVariables': {
      'positionX': null,
      'positionY': 'exponent',
      'column': null,
      'row': null,
      'length': null,
      'area': 'mantissa',
      'colorIntensity': null,
      'colorHue': null,
      'shape': 'Category',
    },
  },
  {
    'id': 124,
    'dataset': {
      'dataset': 'nominalSmall',
      'attribute': 'Category',
      'type': 'nominal',
      'range': 7,
    },
    'selectedColumns': [
      'mantissa',
      'exponent',
      'Category',
    ],
    'mark': 'point',
    'visualVariables': {
      'positionX': null,
      'positionY': 'exponent',
      'column': null,
      'row': null,
      'length': null,
      'area': null,
      'colorIntensity': 'mantissa',
      'colorHue': null,
      'shape': 'Category',
    },
  },
  {
    'id': 129,
    'dataset': {
      'dataset': 'nominalSmall',
      'attribute': 'Category',
      'type': 'nominal',
      'range': 7,
    },
    'selectedColumns': [
      'mantissa',
      'exponent',
      'Category',
    ],
    'mark': 'point',
    'visualVariables': {
      'positionX': 'mantissa',
      'positionY': null,
      'column': null,
      'row': 'exponent',
      'length': null,
      'area': null,
      'colorIntensity': null,
      'colorHue': null,
      'shape': 'Category',
    },
  },
  {
    'id': 130,
    'dataset': {
      'dataset': 'nominalSmall',
      'attribute': 'Category',
      'type': 'nominal',
      'range': 7,
    },
    'selectedColumns': [
      'mantissa',
      'exponent',
      'Category',
    ],
    'mark': 'point',
    'visualVariables': {
      'positionX': null,
      'positionY': 'mantissa',
      'column': null,
      'row': 'exponent',
      'length': null,
      'area': null,
      'colorIntensity': null,
      'colorHue': null,
      'shape': 'Category',
    },
  },
  {
    'id': 131,
    'dataset': {
      'dataset': 'nominalSmall',
      'attribute': 'Category',
      'type': 'nominal',
      'range': 7,
    },
    'selectedColumns': [
      'mantissa',
      'exponent',
      'Category',
    ],
    'mark': 'point',
    'visualVariables': {
      'positionX': null,
      'positionY': null,
      'column': null,
      'row': 'exponent',
      'length': null,
      'area': 'mantissa',
      'colorIntensity': null,
      'colorHue': null,
      'shape': 'Category',
    },
  },
  {
    'id': 132,
    'dataset': {
      'dataset': 'nominalSmall',
      'attribute': 'Category',
      'type': 'nominal',
      'range': 7,
    },
    'selectedColumns': [
      'mantissa',
      'exponent',
      'Category',
    ],
    'mark': 'point',
    'visualVariables': {
      'positionX': null,
      'positionY': null,
      'column': null,
      'row': 'exponent',
      'length': null,
      'area': null,
      'colorIntensity': 'mantissa',
      'colorHue': null,
      'shape': 'Category',
    },
  },
  {
    'id': 134,
    'dataset': {
      'dataset': 'nominalSmall',
      'attribute': 'Category',
      'type': 'nominal',
      'range': 7,
    },
    'selectedColumns': [
      'mantissa',
      'exponent',
      'Category',
    ],
    'mark': 'point',
    'visualVariables': {
      'positionX': null,
      'positionY': 'mantissa',
      'column': null,
      'row': null,
      'length': null,
      'area': 'exponent',
      'colorIntensity': null,
      'colorHue': null,
      'shape': 'Category',
    },
  },
  {
    'id': 136,
    'dataset': {
      'dataset': 'nominalSmall',
      'attribute': 'Category',
      'type': 'nominal',
      'range': 7,
    },
    'selectedColumns': [
      'mantissa',
      'exponent',
      'Category',
    ],
    'mark': 'point',
    'visualVariables': {
      'positionX': null,
      'positionY': 'mantissa',
      'column': null,
      'row': null,
      'length': null,
      'area': null,
      'colorIntensity': 'exponent',
      'colorHue': null,
      'shape': 'Category',
    },
  },
  {
    'id': 137,
    'dataset': {
      'dataset': 'nominalSmall',
      'attribute': 'Category',
      'type': 'nominal',
      'range': 7,
    },
    'selectedColumns': [
      'mantissa',
      'exponent',
      'Category',
    ],
    'mark': 'line',
    'visualVariables': {
      'positionX': 'Category',
      'positionY': 'exponent+mantissa',
      'column': null,
      'row': null,
      'length': null,
      'area': null,
      'colorIntensity': null,
      'colorHue': null,
      'shape': null,
    },
  },
  {
    'id': 138,
    'dataset': {
      'dataset': 'nominalSmall',
      'attribute': 'Category',
      'type': 'nominal',
      'range': 7,
    },
    'selectedColumns': [
      'mantissa',
      'exponent',
      'Category',
    ],
    'mark': 'line',
    'visualVariables': {
      'positionX': 'Category',
      'positionY': 'exponent',
      'column': null,
      'row': null,
      'length': 'mantissa',
      'area': null,
      'colorIntensity': null,
      'colorHue': null,
      'shape': null,
    },
  },
  {
    'id': 139,
    'dataset': {
      'dataset': 'nominalSmall',
      'attribute': 'Category',
      'type': 'nominal',
      'range': 7,
    },
    'selectedColumns': [
      'mantissa',
      'exponent',
      'Category',
    ],
    'mark': 'line',
    'visualVariables': {
      'positionX': 'Category',
      'positionY': 'exponent',
      'column': null,
      'row': null,
      'length': null,
      'area': null,
      'colorIntensity': 'mantissa',
      'colorHue': null,
      'shape': null,
    },
  },
  {
    'id': 141,
    'dataset': {
      'dataset': 'nominalSmall',
      'attribute': 'Category',
      'type': 'nominal',
      'range': 7,
    },
    'selectedColumns': [
      'mantissa',
      'exponent',
      'Category',
    ],
    'mark': 'line',
    'visualVariables': {
      'positionX': 'Category',
      'positionY': 'mantissa',
      'column': null,
      'row': 'exponent',
      'length': null,
      'area': null,
      'colorIntensity': null,
      'colorHue': null,
      'shape': null,
    },
  },
  {
    'id': 142,
    'dataset': {
      'dataset': 'nominalSmall',
      'attribute': 'Category',
      'type': 'nominal',
      'range': 7,
    },
    'selectedColumns': [
      'mantissa',
      'exponent',
      'Category',
    ],
    'mark': 'line',
    'visualVariables': {
      'positionX': 'Category',
      'positionY': 'mantissa',
      'column': null,
      'row': null,
      'length': 'exponent',
      'area': null,
      'colorIntensity': null,
      'colorHue': null,
      'shape': null,
    },
  },
  {
    'id': 143,
    'dataset': {
      'dataset': 'nominalSmall',
      'attribute': 'Category',
      'type': 'nominal',
      'range': 7,
    },
    'selectedColumns': [
      'mantissa',
      'exponent',
      'Category',
    ],
    'mark': 'line',
    'visualVariables': {
      'positionX': 'Category',
      'positionY': 'mantissa',
      'column': null,
      'row': null,
      'length': null,
      'area': null,
      'colorIntensity': 'exponent',
      'colorHue': null,
      'shape': null,
    },
  },
  {
    'id': 148,
    'dataset': {
      'dataset': 'nominalSmall',
      'attribute': 'Category',
      'type': 'nominal',
      'range': 7,
    },
    'selectedColumns': [
      'mantissa',
      'exponent',
      'Category',
    ],
    'mark': 'line',
    'visualVariables': {
      'positionX': 'mantissa',
      'positionY': 'Category',
      'column': null,
      'row': 'exponent',
      'length': null,
      'area': null,
      'colorIntensity': null,
      'colorHue': null,
      'shape': null,
    },
  },
  {
    'id': 151,
    'dataset': {
      'dataset': 'quantitativeSmall',
      'attribute': 'LikesPerComment',
      'type': 'quantitative',
      'range': 7,
    },
    'selectedColumns': [
      'mantissa',
      'exponent',
      'LikesPerComment',
    ],
    'mark': 'point',
    'visualVariables': {
      'positionX': 'LikesPerComment',
      'positionY': 'exponent+mantissa',
      'column': null,
      'row': null,
      'length': null,
      'area': null,
      'colorIntensity': null,
      'colorHue': null,
      'shape': null,
    },
  },
  {
    'id': 152,
    'dataset': {
      'dataset': 'quantitativeSmall',
      'attribute': 'LikesPerComment',
      'type': 'quantitative',
      'range': 7,
    },
    'selectedColumns': [
      'mantissa',
      'exponent',
      'LikesPerComment',
    ],
    'mark': 'point',
    'visualVariables': {
      'positionX': 'LikesPerComment',
      'positionY': 'exponent',
      'column': null,
      'row': null,
      'length': 'mantissa',
      'area': null,
      'colorIntensity': null,
      'colorHue': null,
      'shape': null,
    },
  },
  {
    'id': 153,
    'dataset': {
      'dataset': 'quantitativeSmall',
      'attribute': 'LikesPerComment',
      'type': 'quantitative',
      'range': 7,
    },
    'selectedColumns': [
      'mantissa',
      'exponent',
      'LikesPerComment',
    ],
    'mark': 'point',
    'visualVariables': {
      'positionX': 'LikesPerComment',
      'positionY': 'exponent',
      'column': null,
      'row': null,
      'length': null,
      'area': 'mantissa',
      'colorIntensity': null,
      'colorHue': null,
      'shape': null,
    },
  },
  {
    'id': 154,
    'dataset': {
      'dataset': 'quantitativeSmall',
      'attribute': 'LikesPerComment',
      'type': 'quantitative',
      'range': 7,
    },
    'selectedColumns': [
      'mantissa',
      'exponent',
      'LikesPerComment',
    ],
    'mark': 'point',
    'visualVariables': {
      'positionX': 'LikesPerComment',
      'positionY': 'exponent',
      'column': null,
      'row': null,
      'length': null,
      'area': null,
      'colorIntensity': 'mantissa',
      'colorHue': null,
      'shape': null,
    },
  },
  {
    'id': 159,
    'dataset': {
      'dataset': 'quantitativeSmall',
      'attribute': 'LikesPerComment',
      'type': 'quantitative',
      'range': 7,
    },
    'selectedColumns': [
      'mantissa',
      'exponent',
      'LikesPerComment',
    ],
    'mark': 'point',
    'visualVariables': {
      'positionX': 'LikesPerComment',
      'positionY': 'mantissa',
      'column': null,
      'row': 'exponent',
      'length': null,
      'area': null,
      'colorIntensity': null,
      'colorHue': null,
      'shape': null,
    },
  },
  {
    'id': 160,
    'dataset': {
      'dataset': 'quantitativeSmall',
      'attribute': 'LikesPerComment',
      'type': 'quantitative',
      'range': 7,
    },
    'selectedColumns': [
      'mantissa',
      'exponent',
      'LikesPerComment',
    ],
    'mark': 'point',
    'visualVariables': {
      'positionX': 'LikesPerComment',
      'positionY': null,
      'column': null,
      'row': 'exponent',
      'length': 'mantissa',
      'area': null,
      'colorIntensity': null,
      'colorHue': null,
      'shape': null,
    },
  },
  {
    'id': 161,
    'dataset': {
      'dataset': 'quantitativeSmall',
      'attribute': 'LikesPerComment',
      'type': 'quantitative',
      'range': 7,
    },
    'selectedColumns': [
      'mantissa',
      'exponent',
      'LikesPerComment',
    ],
    'mark': 'point',
    'visualVariables': {
      'positionX': 'LikesPerComment',
      'positionY': null,
      'column': null,
      'row': 'exponent',
      'length': null,
      'area': 'mantissa',
      'colorIntensity': null,
      'colorHue': null,
      'shape': null,
    },
  },
  {
    'id': 162,
    'dataset': {
      'dataset': 'quantitativeSmall',
      'attribute': 'LikesPerComment',
      'type': 'quantitative',
      'range': 7,
    },
    'selectedColumns': [
      'mantissa',
      'exponent',
      'LikesPerComment',
    ],
    'mark': 'point',
    'visualVariables': {
      'positionX': 'LikesPerComment',
      'positionY': null,
      'column': null,
      'row': 'exponent',
      'length': null,
      'area': null,
      'colorIntensity': 'mantissa',
      'colorHue': null,
      'shape': null,
    },
  },
  {
    'id': 163,
    'dataset': {
      'dataset': 'quantitativeSmall',
      'attribute': 'LikesPerComment',
      'type': 'quantitative',
      'range': 7,
    },
    'selectedColumns': [
      'mantissa',
      'exponent',
      'LikesPerComment',
    ],
    'mark': 'point',
    'visualVariables': {
      'positionX': 'LikesPerComment',
      'positionY': 'mantissa',
      'column': null,
      'row': null,
      'length': 'exponent',
      'area': null,
      'colorIntensity': null,
      'colorHue': null,
      'shape': null,
    },
  },
  {
    'id': 164,
    'dataset': {
      'dataset': 'quantitativeSmall',
      'attribute': 'LikesPerComment',
      'type': 'quantitative',
      'range': 7,
    },
    'selectedColumns': [
      'mantissa',
      'exponent',
      'LikesPerComment',
    ],
    'mark': 'point',
    'visualVariables': {
      'positionX': 'LikesPerComment',
      'positionY': null,
      'column': null,
      'row': null,
      'length': 'exponent',
      'area': 'mantissa',
      'colorIntensity': null,
      'colorHue': null,
      'shape': null,
    },
  },
  {
    'id': 165,
    'dataset': {
      'dataset': 'quantitativeSmall',
      'attribute': 'LikesPerComment',
      'type': 'quantitative',
      'range': 7,
    },
    'selectedColumns': [
      'mantissa',
      'exponent',
      'LikesPerComment',
    ],
    'mark': 'point',
    'visualVariables': {
      'positionX': 'LikesPerComment',
      'positionY': null,
      'column': null,
      'row': null,
      'length': 'exponent',
      'area': null,
      'colorIntensity': 'mantissa',
      'colorHue': null,
      'shape': null,
    },
  },
  {
    'id': 166,
    'dataset': {
      'dataset': 'quantitativeSmall',
      'attribute': 'LikesPerComment',
      'type': 'quantitative',
      'range': 7,
    },
    'selectedColumns': [
      'mantissa',
      'exponent',
      'LikesPerComment',
    ],
    'mark': 'point',
    'visualVariables': {
      'positionX': 'LikesPerComment',
      'positionY': 'mantissa',
      'column': null,
      'row': null,
      'length': null,
      'area': 'exponent',
      'colorIntensity': null,
      'colorHue': null,
      'shape': null,
    },
  },
  {
    'id': 167,
    'dataset': {
      'dataset': 'quantitativeSmall',
      'attribute': 'LikesPerComment',
      'type': 'quantitative',
      'range': 7,
    },
    'selectedColumns': [
      'mantissa',
      'exponent',
      'LikesPerComment',
    ],
    'mark': 'point',
    'visualVariables': {
      'positionX': 'LikesPerComment',
      'positionY': null,
      'column': null,
      'row': null,
      'length': 'mantissa',
      'area': 'exponent',
      'colorIntensity': null,
      'colorHue': null,
      'shape': null,
    },
  },
  {
    'id': 168,
    'dataset': {
      'dataset': 'quantitativeSmall',
      'attribute': 'LikesPerComment',
      'type': 'quantitative',
      'range': 7,
    },
    'selectedColumns': [
      'mantissa',
      'exponent',
      'LikesPerComment',
    ],
    'mark': 'point',
    'visualVariables': {
      'positionX': 'LikesPerComment',
      'positionY': null,
      'column': null,
      'row': null,
      'length': null,
      'area': 'exponent',
      'colorIntensity': 'mantissa',
      'colorHue': null,
      'shape': null,
    },
  },
  {
    'id': 169,
    'dataset': {
      'dataset': 'quantitativeSmall',
      'attribute': 'LikesPerComment',
      'type': 'quantitative',
      'range': 7,
    },
    'selectedColumns': [
      'mantissa',
      'exponent',
      'LikesPerComment',
    ],
    'mark': 'point',
    'visualVariables': {
      'positionX': 'LikesPerComment',
      'positionY': 'mantissa',
      'column': null,
      'row': null,
      'length': null,
      'area': null,
      'colorIntensity': 'exponent',
      'colorHue': null,
      'shape': null,
    },
  },
  {
    'id': 170,
    'dataset': {
      'dataset': 'quantitativeSmall',
      'attribute': 'LikesPerComment',
      'type': 'quantitative',
      'range': 7,
    },
    'selectedColumns': [
      'mantissa',
      'exponent',
      'LikesPerComment',
    ],
    'mark': 'point',
    'visualVariables': {
      'positionX': 'LikesPerComment',
      'positionY': null,
      'column': null,
      'row': null,
      'length': 'mantissa',
      'area': null,
      'colorIntensity': 'exponent',
      'colorHue': null,
      'shape': null,
    },
  },
  {
    'id': 171,
    'dataset': {
      'dataset': 'quantitativeSmall',
      'attribute': 'LikesPerComment',
      'type': 'quantitative',
      'range': 7,
    },
    'selectedColumns': [
      'mantissa',
      'exponent',
      'LikesPerComment',
    ],
    'mark': 'point',
    'visualVariables': {
      'positionX': 'LikesPerComment',
      'positionY': null,
      'column': null,
      'row': null,
      'length': null,
      'area': 'mantissa',
      'colorIntensity': 'exponent',
      'colorHue': null,
      'shape': null,
    },
  },
  {
    'id': 197,
    'dataset': {
      'dataset': 'quantitativeSmall',
      'attribute': 'LikesPerComment',
      'type': 'quantitative',
      'range': 7,
    },
    'selectedColumns': [
      'mantissa',
      'exponent',
      'LikesPerComment',
    ],
    'mark': 'point',
    'visualVariables': {
      'positionX': 'mantissa',
      'positionY': 'exponent',
      'column': null,
      'row': null,
      'length': 'LikesPerComment',
      'area': null,
      'colorIntensity': null,
      'colorHue': null,
      'shape': null,
    },
  },
  {
    'id': 198,
    'dataset': {
      'dataset': 'quantitativeSmall',
      'attribute': 'LikesPerComment',
      'type': 'quantitative',
      'range': 7,
    },
    'selectedColumns': [
      'mantissa',
      'exponent',
      'LikesPerComment',
    ],
    'mark': 'point',
    'visualVariables': {
      'positionX': null,
      'positionY': 'exponent+mantissa',
      'column': null,
      'row': null,
      'length': 'LikesPerComment',
      'area': null,
      'colorIntensity': null,
      'colorHue': null,
      'shape': null,
    },
  },
  {
    'id': 199,
    'dataset': {
      'dataset': 'quantitativeSmall',
      'attribute': 'LikesPerComment',
      'type': 'quantitative',
      'range': 7,
    },
    'selectedColumns': [
      'mantissa',
      'exponent',
      'LikesPerComment',
    ],
    'mark': 'point',
    'visualVariables': {
      'positionX': null,
      'positionY': 'exponent',
      'column': null,
      'row': null,
      'length': 'LikesPerComment',
      'area': 'mantissa',
      'colorIntensity': null,
      'colorHue': null,
      'shape': null,
    },
  },
  {
    'id': 200,
    'dataset': {
      'dataset': 'quantitativeSmall',
      'attribute': 'LikesPerComment',
      'type': 'quantitative',
      'range': 7,
    },
    'selectedColumns': [
      'mantissa',
      'exponent',
      'LikesPerComment',
    ],
    'mark': 'point',
    'visualVariables': {
      'positionX': null,
      'positionY': 'exponent',
      'column': null,
      'row': null,
      'length': 'LikesPerComment',
      'area': null,
      'colorIntensity': 'mantissa',
      'colorHue': null,
      'shape': null,
    },
  },
  {
    'id': 205,
    'dataset': {
      'dataset': 'quantitativeSmall',
      'attribute': 'LikesPerComment',
      'type': 'quantitative',
      'range': 7,
    },
    'selectedColumns': [
      'mantissa',
      'exponent',
      'LikesPerComment',
    ],
    'mark': 'point',
    'visualVariables': {
      'positionX': 'mantissa',
      'positionY': null,
      'column': null,
      'row': 'exponent',
      'length': 'LikesPerComment',
      'area': null,
      'colorIntensity': null,
      'colorHue': null,
      'shape': null,
    },
  },
  {
    'id': 206,
    'dataset': {
      'dataset': 'quantitativeSmall',
      'attribute': 'LikesPerComment',
      'type': 'quantitative',
      'range': 7,
    },
    'selectedColumns': [
      'mantissa',
      'exponent',
      'LikesPerComment',
    ],
    'mark': 'point',
    'visualVariables': {
      'positionX': null,
      'positionY': 'mantissa',
      'column': null,
      'row': 'exponent',
      'length': 'LikesPerComment',
      'area': null,
      'colorIntensity': null,
      'colorHue': null,
      'shape': null,
    },
  },
  {
    'id': 207,
    'dataset': {
      'dataset': 'quantitativeSmall',
      'attribute': 'LikesPerComment',
      'type': 'quantitative',
      'range': 7,
    },
    'selectedColumns': [
      'mantissa',
      'exponent',
      'LikesPerComment',
    ],
    'mark': 'point',
    'visualVariables': {
      'positionX': null,
      'positionY': null,
      'column': null,
      'row': 'exponent',
      'length': 'LikesPerComment',
      'area': 'mantissa',
      'colorIntensity': null,
      'colorHue': null,
      'shape': null,
    },
  },
  {
    'id': 208,
    'dataset': {
      'dataset': 'quantitativeSmall',
      'attribute': 'LikesPerComment',
      'type': 'quantitative',
      'range': 7,
    },
    'selectedColumns': [
      'mantissa',
      'exponent',
      'LikesPerComment',
    ],
    'mark': 'point',
    'visualVariables': {
      'positionX': null,
      'positionY': null,
      'column': null,
      'row': 'exponent',
      'length': 'LikesPerComment',
      'area': null,
      'colorIntensity': 'mantissa',
      'colorHue': null,
      'shape': null,
    },
  },
  {
    'id': 210,
    'dataset': {
      'dataset': 'quantitativeSmall',
      'attribute': 'LikesPerComment',
      'type': 'quantitative',
      'range': 7,
    },
    'selectedColumns': [
      'mantissa',
      'exponent',
      'LikesPerComment',
    ],
    'mark': 'point',
    'visualVariables': {
      'positionX': null,
      'positionY': 'mantissa',
      'column': null,
      'row': null,
      'length': 'LikesPerComment',
      'area': 'exponent',
      'colorIntensity': null,
      'colorHue': null,
      'shape': null,
    },
  },
  {
    'id': 212,
    'dataset': {
      'dataset': 'quantitativeSmall',
      'attribute': 'LikesPerComment',
      'type': 'quantitative',
      'range': 7,
    },
    'selectedColumns': [
      'mantissa',
      'exponent',
      'LikesPerComment',
    ],
    'mark': 'point',
    'visualVariables': {
      'positionX': null,
      'positionY': 'mantissa',
      'column': null,
      'row': null,
      'length': 'LikesPerComment',
      'area': null,
      'colorIntensity': 'exponent',
      'colorHue': null,
      'shape': null,
    },
  },
  {
    'id': 217,
    'dataset': {
      'dataset': 'quantitativeSmall',
      'attribute': 'LikesPerComment',
      'type': 'quantitative',
      'range': 7,
    },
    'selectedColumns': [
      'mantissa',
      'exponent',
      'LikesPerComment',
    ],
    'mark': 'point',
    'visualVariables': {
      'positionX': 'mantissa',
      'positionY': 'exponent',
      'column': null,
      'row': null,
      'length': null,
      'area': 'LikesPerComment',
      'colorIntensity': null,
      'colorHue': null,
      'shape': null,
    },
  },
  {
    'id': 218,
    'dataset': {
      'dataset': 'quantitativeSmall',
      'attribute': 'LikesPerComment',
      'type': 'quantitative',
      'range': 7,
    },
    'selectedColumns': [
      'mantissa',
      'exponent',
      'LikesPerComment',
    ],
    'mark': 'point',
    'visualVariables': {
      'positionX': null,
      'positionY': 'exponent+mantissa',
      'column': null,
      'row': null,
      'length': null,
      'area': 'LikesPerComment',
      'colorIntensity': null,
      'colorHue': null,
      'shape': null,
    },
  },
  {
    'id': 219,
    'dataset': {
      'dataset': 'quantitativeSmall',
      'attribute': 'LikesPerComment',
      'type': 'quantitative',
      'range': 7,
    },
    'selectedColumns': [
      'mantissa',
      'exponent',
      'LikesPerComment',
    ],
    'mark': 'point',
    'visualVariables': {
      'positionX': null,
      'positionY': 'exponent',
      'column': null,
      'row': null,
      'length': 'mantissa',
      'area': 'LikesPerComment',
      'colorIntensity': null,
      'colorHue': null,
      'shape': null,
    },
  },
  {
    'id': 220,
    'dataset': {
      'dataset': 'quantitativeSmall',
      'attribute': 'LikesPerComment',
      'type': 'quantitative',
      'range': 7,
    },
    'selectedColumns': [
      'mantissa',
      'exponent',
      'LikesPerComment',
    ],
    'mark': 'point',
    'visualVariables': {
      'positionX': null,
      'positionY': 'exponent',
      'column': null,
      'row': null,
      'length': null,
      'area': 'LikesPerComment',
      'colorIntensity': 'mantissa',
      'colorHue': null,
      'shape': null,
    },
  },
  {
    'id': 225,
    'dataset': {
      'dataset': 'quantitativeSmall',
      'attribute': 'LikesPerComment',
      'type': 'quantitative',
      'range': 7,
    },
    'selectedColumns': [
      'mantissa',
      'exponent',
      'LikesPerComment',
    ],
    'mark': 'point',
    'visualVariables': {
      'positionX': 'mantissa',
      'positionY': null,
      'column': null,
      'row': 'exponent',
      'length': null,
      'area': 'LikesPerComment',
      'colorIntensity': null,
      'colorHue': null,
      'shape': null,
    },
  },
  {
    'id': 226,
    'dataset': {
      'dataset': 'quantitativeSmall',
      'attribute': 'LikesPerComment',
      'type': 'quantitative',
      'range': 7,
    },
    'selectedColumns': [
      'mantissa',
      'exponent',
      'LikesPerComment',
    ],
    'mark': 'point',
    'visualVariables': {
      'positionX': null,
      'positionY': 'mantissa',
      'column': null,
      'row': 'exponent',
      'length': null,
      'area': 'LikesPerComment',
      'colorIntensity': null,
      'colorHue': null,
      'shape': null,
    },
  },
  {
    'id': 227,
    'dataset': {
      'dataset': 'quantitativeSmall',
      'attribute': 'LikesPerComment',
      'type': 'quantitative',
      'range': 7,
    },
    'selectedColumns': [
      'mantissa',
      'exponent',
      'LikesPerComment',
    ],
    'mark': 'point',
    'visualVariables': {
      'positionX': null,
      'positionY': null,
      'column': null,
      'row': 'exponent',
      'length': 'mantissa',
      'area': 'LikesPerComment',
      'colorIntensity': null,
      'colorHue': null,
      'shape': null,
    },
  },
  {
    'id': 228,
    'dataset': {
      'dataset': 'quantitativeSmall',
      'attribute': 'LikesPerComment',
      'type': 'quantitative',
      'range': 7,
    },
    'selectedColumns': [
      'mantissa',
      'exponent',
      'LikesPerComment',
    ],
    'mark': 'point',
    'visualVariables': {
      'positionX': null,
      'positionY': null,
      'column': null,
      'row': 'exponent',
      'length': null,
      'area': 'LikesPerComment',
      'colorIntensity': 'mantissa',
      'colorHue': null,
      'shape': null,
    },
  },
  {
    'id': 230,
    'dataset': {
      'dataset': 'quantitativeSmall',
      'attribute': 'LikesPerComment',
      'type': 'quantitative',
      'range': 7,
    },
    'selectedColumns': [
      'mantissa',
      'exponent',
      'LikesPerComment',
    ],
    'mark': 'point',
    'visualVariables': {
      'positionX': null,
      'positionY': 'mantissa',
      'column': null,
      'row': null,
      'length': 'exponent',
      'area': 'LikesPerComment',
      'colorIntensity': null,
      'colorHue': null,
      'shape': null,
    },
  },
  {
    'id': 232,
    'dataset': {
      'dataset': 'quantitativeSmall',
      'attribute': 'LikesPerComment',
      'type': 'quantitative',
      'range': 7,
    },
    'selectedColumns': [
      'mantissa',
      'exponent',
      'LikesPerComment',
    ],
    'mark': 'point',
    'visualVariables': {
      'positionX': null,
      'positionY': 'mantissa',
      'column': null,
      'row': null,
      'length': null,
      'area': 'LikesPerComment',
      'colorIntensity': 'exponent',
      'colorHue': null,
      'shape': null,
    },
  },
  {
    'id': 237,
    'dataset': {
      'dataset': 'quantitativeSmall',
      'attribute': 'LikesPerComment',
      'type': 'quantitative',
      'range': 7,
    },
    'selectedColumns': [
      'mantissa',
      'exponent',
      'LikesPerComment',
    ],
    'mark': 'point',
    'visualVariables': {
      'positionX': 'mantissa',
      'positionY': 'exponent',
      'column': null,
      'row': null,
      'length': null,
      'area': null,
      'colorIntensity': 'LikesPerComment',
      'colorHue': null,
      'shape': null,
    },
  },
  {
    'id': 238,
    'dataset': {
      'dataset': 'quantitativeSmall',
      'attribute': 'LikesPerComment',
      'type': 'quantitative',
      'range': 7,
    },
    'selectedColumns': [
      'mantissa',
      'exponent',
      'LikesPerComment',
    ],
    'mark': 'point',
    'visualVariables': {
      'positionX': null,
      'positionY': 'exponent+mantissa',
      'column': null,
      'row': null,
      'length': null,
      'area': null,
      'colorIntensity': 'LikesPerComment',
      'colorHue': null,
      'shape': null,
    },
  },
  {
    'id': 239,
    'dataset': {
      'dataset': 'quantitativeSmall',
      'attribute': 'LikesPerComment',
      'type': 'quantitative',
      'range': 7,
    },
    'selectedColumns': [
      'mantissa',
      'exponent',
      'LikesPerComment',
    ],
    'mark': 'point',
    'visualVariables': {
      'positionX': null,
      'positionY': 'exponent',
      'column': null,
      'row': null,
      'length': 'mantissa',
      'area': null,
      'colorIntensity': 'LikesPerComment',
      'colorHue': null,
      'shape': null,
    },
  },
  {
    'id': 240,
    'dataset': {
      'dataset': 'quantitativeSmall',
      'attribute': 'LikesPerComment',
      'type': 'quantitative',
      'range': 7,
    },
    'selectedColumns': [
      'mantissa',
      'exponent',
      'LikesPerComment',
    ],
    'mark': 'point',
    'visualVariables': {
      'positionX': null,
      'positionY': 'exponent',
      'column': null,
      'row': null,
      'length': null,
      'area': 'mantissa',
      'colorIntensity': 'LikesPerComment',
      'colorHue': null,
      'shape': null,
    },
  },
  {
    'id': 245,
    'dataset': {
      'dataset': 'quantitativeSmall',
      'attribute': 'LikesPerComment',
      'type': 'quantitative',
      'range': 7,
    },
    'selectedColumns': [
      'mantissa',
      'exponent',
      'LikesPerComment',
    ],
    'mark': 'point',
    'visualVariables': {
      'positionX': 'mantissa',
      'positionY': null,
      'column': null,
      'row': 'exponent',
      'length': null,
      'area': null,
      'colorIntensity': 'LikesPerComment',
      'colorHue': null,
      'shape': null,
    },
  },
  {
    'id': 246,
    'dataset': {
      'dataset': 'quantitativeSmall',
      'attribute': 'LikesPerComment',
      'type': 'quantitative',
      'range': 7,
    },
    'selectedColumns': [
      'mantissa',
      'exponent',
      'LikesPerComment',
    ],
    'mark': 'point',
    'visualVariables': {
      'positionX': null,
      'positionY': 'mantissa',
      'column': null,
      'row': 'exponent',
      'length': null,
      'area': null,
      'colorIntensity': 'LikesPerComment',
      'colorHue': null,
      'shape': null,
    },
  },
  {
    'id': 247,
    'dataset': {
      'dataset': 'quantitativeSmall',
      'attribute': 'LikesPerComment',
      'type': 'quantitative',
      'range': 7,
    },
    'selectedColumns': [
      'mantissa',
      'exponent',
      'LikesPerComment',
    ],
    'mark': 'point',
    'visualVariables': {
      'positionX': null,
      'positionY': null,
      'column': null,
      'row': 'exponent',
      'length': 'mantissa',
      'area': null,
      'colorIntensity': 'LikesPerComment',
      'colorHue': null,
      'shape': null,
    },
  },
  {
    'id': 248,
    'dataset': {
      'dataset': 'quantitativeSmall',
      'attribute': 'LikesPerComment',
      'type': 'quantitative',
      'range': 7,
    },
    'selectedColumns': [
      'mantissa',
      'exponent',
      'LikesPerComment',
    ],
    'mark': 'point',
    'visualVariables': {
      'positionX': null,
      'positionY': null,
      'column': null,
      'row': 'exponent',
      'length': null,
      'area': 'mantissa',
      'colorIntensity': 'LikesPerComment',
      'colorHue': null,
      'shape': null,
    },
  },
  {
    'id': 250,
    'dataset': {
      'dataset': 'quantitativeSmall',
      'attribute': 'LikesPerComment',
      'type': 'quantitative',
      'range': 7,
    },
    'selectedColumns': [
      'mantissa',
      'exponent',
      'LikesPerComment',
    ],
    'mark': 'point',
    'visualVariables': {
      'positionX': null,
      'positionY': 'mantissa',
      'column': null,
      'row': null,
      'length': 'exponent',
      'area': null,
      'colorIntensity': 'LikesPerComment',
      'colorHue': null,
      'shape': null,
    },
  },
  {
    'id': 252,
    'dataset': {
      'dataset': 'quantitativeSmall',
      'attribute': 'LikesPerComment',
      'type': 'quantitative',
      'range': 7,
    },
    'selectedColumns': [
      'mantissa',
      'exponent',
      'LikesPerComment',
    ],
    'mark': 'point',
    'visualVariables': {
      'positionX': null,
      'positionY': 'mantissa',
      'column': null,
      'row': null,
      'length': null,
      'area': 'exponent',
      'colorIntensity': 'LikesPerComment',
      'colorHue': null,
      'shape': null,
    },
  },
  {
    'id': 253,
    'dataset': {
      'dataset': 'rankingSmall',
      'attribute': 'Sentiment',
      'type': 'ordinal',
      'range': 7,
    },
    'selectedColumns': [
      'mantissa',
      'exponent',
      'Sentiment',
    ],
    'mark': 'point',
    'visualVariables': {
      'positionX': 'Sentiment',
      'positionY': 'exponent+mantissa',
      'column': null,
      'row': null,
      'length': null,
      'area': null,
      'colorIntensity': null,
      'colorHue': null,
      'shape': null,
    },
  },
  {
    'id': 254,
    'dataset': {
      'dataset': 'rankingSmall',
      'attribute': 'Sentiment',
      'type': 'ordinal',
      'range': 7,
    },
    'selectedColumns': [
      'mantissa',
      'exponent',
      'Sentiment',
    ],
    'mark': 'point',
    'visualVariables': {
      'positionX': 'Sentiment',
      'positionY': 'exponent',
      'column': null,
      'row': null,
      'length': 'mantissa',
      'area': null,
      'colorIntensity': null,
      'colorHue': null,
      'shape': null,
    },
  },
  {
    'id': 255,
    'dataset': {
      'dataset': 'rankingSmall',
      'attribute': 'Sentiment',
      'type': 'ordinal',
      'range': 7,
    },
    'selectedColumns': [
      'mantissa',
      'exponent',
      'Sentiment',
    ],
    'mark': 'point',
    'visualVariables': {
      'positionX': 'Sentiment',
      'positionY': 'exponent',
      'column': null,
      'row': null,
      'length': null,
      'area': 'mantissa',
      'colorIntensity': null,
      'colorHue': null,
      'shape': null,
    },
  },
  {
    'id': 256,
    'dataset': {
      'dataset': 'rankingSmall',
      'attribute': 'Sentiment',
      'type': 'ordinal',
      'range': 7,
    },
    'selectedColumns': [
      'mantissa',
      'exponent',
      'Sentiment',
    ],
    'mark': 'point',
    'visualVariables': {
      'positionX': 'Sentiment',
      'positionY': 'exponent',
      'column': null,
      'row': null,
      'length': null,
      'area': null,
      'colorIntensity': 'mantissa',
      'colorHue': null,
      'shape': null,
    },
  },
  {
    'id': 261,
    'dataset': {
      'dataset': 'rankingSmall',
      'attribute': 'Sentiment',
      'type': 'ordinal',
      'range': 7,
    },
    'selectedColumns': [
      'mantissa',
      'exponent',
      'Sentiment',
    ],
    'mark': 'point',
    'visualVariables': {
      'positionX': 'Sentiment',
      'positionY': 'mantissa',
      'column': null,
      'row': 'exponent',
      'length': null,
      'area': null,
      'colorIntensity': null,
      'colorHue': null,
      'shape': null,
    },
  },
  {
    'id': 262,
    'dataset': {
      'dataset': 'rankingSmall',
      'attribute': 'Sentiment',
      'type': 'ordinal',
      'range': 7,
    },
    'selectedColumns': [
      'mantissa',
      'exponent',
      'Sentiment',
    ],
    'mark': 'point',
    'visualVariables': {
      'positionX': 'Sentiment',
      'positionY': null,
      'column': null,
      'row': 'exponent',
      'length': 'mantissa',
      'area': null,
      'colorIntensity': null,
      'colorHue': null,
      'shape': null,
    },
  },
  {
    'id': 263,
    'dataset': {
      'dataset': 'rankingSmall',
      'attribute': 'Sentiment',
      'type': 'ordinal',
      'range': 7,
    },
    'selectedColumns': [
      'mantissa',
      'exponent',
      'Sentiment',
    ],
    'mark': 'point',
    'visualVariables': {
      'positionX': 'Sentiment',
      'positionY': null,
      'column': null,
      'row': 'exponent',
      'length': null,
      'area': 'mantissa',
      'colorIntensity': null,
      'colorHue': null,
      'shape': null,
    },
  },
  {
    'id': 264,
    'dataset': {
      'dataset': 'rankingSmall',
      'attribute': 'Sentiment',
      'type': 'ordinal',
      'range': 7,
    },
    'selectedColumns': [
      'mantissa',
      'exponent',
      'Sentiment',
    ],
    'mark': 'point',
    'visualVariables': {
      'positionX': 'Sentiment',
      'positionY': null,
      'column': null,
      'row': 'exponent',
      'length': null,
      'area': null,
      'colorIntensity': 'mantissa',
      'colorHue': null,
      'shape': null,
    },
  },
  {
    'id': 265,
    'dataset': {
      'dataset': 'rankingSmall',
      'attribute': 'Sentiment',
      'type': 'ordinal',
      'range': 7,
    },
    'selectedColumns': [
      'mantissa',
      'exponent',
      'Sentiment',
    ],
    'mark': 'point',
    'visualVariables': {
      'positionX': 'Sentiment',
      'positionY': 'mantissa',
      'column': null,
      'row': null,
      'length': 'exponent',
      'area': null,
      'colorIntensity': null,
      'colorHue': null,
      'shape': null,
    },
  },
  {
    'id': 266,
    'dataset': {
      'dataset': 'rankingSmall',
      'attribute': 'Sentiment',
      'type': 'ordinal',
      'range': 7,
    },
    'selectedColumns': [
      'mantissa',
      'exponent',
      'Sentiment',
    ],
    'mark': 'point',
    'visualVariables': {
      'positionX': 'Sentiment',
      'positionY': null,
      'column': null,
      'row': null,
      'length': 'exponent',
      'area': 'mantissa',
      'colorIntensity': null,
      'colorHue': null,
      'shape': null,
    },
  },
  {
    'id': 267,
    'dataset': {
      'dataset': 'rankingSmall',
      'attribute': 'Sentiment',
      'type': 'ordinal',
      'range': 7,
    },
    'selectedColumns': [
      'mantissa',
      'exponent',
      'Sentiment',
    ],
    'mark': 'point',
    'visualVariables': {
      'positionX': 'Sentiment',
      'positionY': null,
      'column': null,
      'row': null,
      'length': 'exponent',
      'area': null,
      'colorIntensity': 'mantissa',
      'colorHue': null,
      'shape': null,
    },
  },
  {
    'id': 268,
    'dataset': {
      'dataset': 'rankingSmall',
      'attribute': 'Sentiment',
      'type': 'ordinal',
      'range': 7,
    },
    'selectedColumns': [
      'mantissa',
      'exponent',
      'Sentiment',
    ],
    'mark': 'point',
    'visualVariables': {
      'positionX': 'Sentiment',
      'positionY': 'mantissa',
      'column': null,
      'row': null,
      'length': null,
      'area': 'exponent',
      'colorIntensity': null,
      'colorHue': null,
      'shape': null,
    },
  },
  {
    'id': 269,
    'dataset': {
      'dataset': 'rankingSmall',
      'attribute': 'Sentiment',
      'type': 'ordinal',
      'range': 7,
    },
    'selectedColumns': [
      'mantissa',
      'exponent',
      'Sentiment',
    ],
    'mark': 'point',
    'visualVariables': {
      'positionX': 'Sentiment',
      'positionY': null,
      'column': null,
      'row': null,
      'length': 'mantissa',
      'area': 'exponent',
      'colorIntensity': null,
      'colorHue': null,
      'shape': null,
    },
  },
  {
    'id': 270,
    'dataset': {
      'dataset': 'rankingSmall',
      'attribute': 'Sentiment',
      'type': 'ordinal',
      'range': 7,
    },
    'selectedColumns': [
      'mantissa',
      'exponent',
      'Sentiment',
    ],
    'mark': 'point',
    'visualVariables': {
      'positionX': 'Sentiment',
      'positionY': null,
      'column': null,
      'row': null,
      'length': null,
      'area': 'exponent',
      'colorIntensity': 'mantissa',
      'colorHue': null,
      'shape': null,
    },
  },
  {
    'id': 271,
    'dataset': {
      'dataset': 'rankingSmall',
      'attribute': 'Sentiment',
      'type': 'ordinal',
      'range': 7,
    },
    'selectedColumns': [
      'mantissa',
      'exponent',
      'Sentiment',
    ],
    'mark': 'point',
    'visualVariables': {
      'positionX': 'Sentiment',
      'positionY': 'mantissa',
      'column': null,
      'row': null,
      'length': null,
      'area': null,
      'colorIntensity': 'exponent',
      'colorHue': null,
      'shape': null,
    },
  },
  {
    'id': 272,
    'dataset': {
      'dataset': 'rankingSmall',
      'attribute': 'Sentiment',
      'type': 'ordinal',
      'range': 7,
    },
    'selectedColumns': [
      'mantissa',
      'exponent',
      'Sentiment',
    ],
    'mark': 'point',
    'visualVariables': {
      'positionX': 'Sentiment',
      'positionY': null,
      'column': null,
      'row': null,
      'length': 'mantissa',
      'area': null,
      'colorIntensity': 'exponent',
      'colorHue': null,
      'shape': null,
    },
  },
  {
    'id': 273,
    'dataset': {
      'dataset': 'rankingSmall',
      'attribute': 'Sentiment',
      'type': 'ordinal',
      'range': 7,
    },
    'selectedColumns': [
      'mantissa',
      'exponent',
      'Sentiment',
    ],
    'mark': 'point',
    'visualVariables': {
      'positionX': 'Sentiment',
      'positionY': null,
      'column': null,
      'row': null,
      'length': null,
      'area': 'mantissa',
      'colorIntensity': 'exponent',
      'colorHue': null,
      'shape': null,
    },
  },
  {
    'id': 305,
    'dataset': {
      'dataset': 'rankingSmall',
      'attribute': 'Sentiment',
      'type': 'ordinal',
      'range': 7,
    },
    'selectedColumns': [
      'mantissa',
      'exponent',
      'Sentiment',
    ],
    'mark': 'point',
    'visualVariables': {
      'positionX': 'mantissa',
      'positionY': null,
      'column': 'Sentiment',
      'row': 'exponent',
      'length': null,
      'area': null,
      'colorIntensity': null,
      'colorHue': null,
      'shape': null,
    },
  },
  {
    'id': 306,
    'dataset': {
      'dataset': 'rankingSmall',
      'attribute': 'Sentiment',
      'type': 'ordinal',
      'range': 7,
    },
    'selectedColumns': [
      'mantissa',
      'exponent',
      'Sentiment',
    ],
    'mark': 'point',
    'visualVariables': {
      'positionX': null,
      'positionY': 'mantissa',
      'column': 'Sentiment',
      'row': 'exponent',
      'length': null,
      'area': null,
      'colorIntensity': null,
      'colorHue': null,
      'shape': null,
    },
  },
  {
    'id': 307,
    'dataset': {
      'dataset': 'rankingSmall',
      'attribute': 'Sentiment',
      'type': 'ordinal',
      'range': 7,
    },
    'selectedColumns': [
      'mantissa',
      'exponent',
      'Sentiment',
    ],
    'mark': 'point',
    'visualVariables': {
      'positionX': null,
      'positionY': null,
      'column': 'Sentiment',
      'row': 'exponent',
      'length': 'mantissa',
      'area': null,
      'colorIntensity': null,
      'colorHue': null,
      'shape': null,
    },
  },
  {
    'id': 308,
    'dataset': {
      'dataset': 'rankingSmall',
      'attribute': 'Sentiment',
      'type': 'ordinal',
      'range': 7,
    },
    'selectedColumns': [
      'mantissa',
      'exponent',
      'Sentiment',
    ],
    'mark': 'point',
    'visualVariables': {
      'positionX': null,
      'positionY': null,
      'column': 'Sentiment',
      'row': 'exponent',
      'length': null,
      'area': 'mantissa',
      'colorIntensity': null,
      'colorHue': null,
      'shape': null,
    },
  },
  {
    'id': 309,
    'dataset': {
      'dataset': 'rankingSmall',
      'attribute': 'Sentiment',
      'type': 'ordinal',
      'range': 7,
    },
    'selectedColumns': [
      'mantissa',
      'exponent',
      'Sentiment',
    ],
    'mark': 'point',
    'visualVariables': {
      'positionX': null,
      'positionY': null,
      'column': 'Sentiment',
      'row': 'exponent',
      'length': null,
      'area': null,
      'colorIntensity': 'mantissa',
      'colorHue': null,
      'shape': null,
    },
  },
  {
    'id': 353,
    'dataset': {
      'dataset': 'rankingSmall',
      'attribute': 'Sentiment',
      'type': 'ordinal',
      'range': 7,
    },
    'selectedColumns': [
      'mantissa',
      'exponent',
      'Sentiment',
    ],
    'mark': 'point',
    'visualVariables': {
      'positionX': 'mantissa',
      'positionY': 'exponent',
      'column': null,
      'row': null,
      'length': null,
      'area': null,
      'colorIntensity': 'Sentiment',
      'colorHue': null,
      'shape': null,
    },
  },
  {
    'id': 354,
    'dataset': {
      'dataset': 'rankingSmall',
      'attribute': 'Sentiment',
      'type': 'ordinal',
      'range': 7,
    },
    'selectedColumns': [
      'mantissa',
      'exponent',
      'Sentiment',
    ],
    'mark': 'point',
    'visualVariables': {
      'positionX': null,
      'positionY': 'exponent+mantissa',
      'column': null,
      'row': null,
      'length': null,
      'area': null,
      'colorIntensity': 'Sentiment',
      'colorHue': null,
      'shape': null,
    },
  },
  {
    'id': 355,
    'dataset': {
      'dataset': 'rankingSmall',
      'attribute': 'Sentiment',
      'type': 'ordinal',
      'range': 7,
    },
    'selectedColumns': [
      'mantissa',
      'exponent',
      'Sentiment',
    ],
    'mark': 'point',
    'visualVariables': {
      'positionX': null,
      'positionY': 'exponent',
      'column': null,
      'row': null,
      'length': 'mantissa',
      'area': null,
      'colorIntensity': 'Sentiment',
      'colorHue': null,
      'shape': null,
    },
  },
  {
    'id': 356,
    'dataset': {
      'dataset': 'rankingSmall',
      'attribute': 'Sentiment',
      'type': 'ordinal',
      'range': 7,
    },
    'selectedColumns': [
      'mantissa',
      'exponent',
      'Sentiment',
    ],
    'mark': 'point',
    'visualVariables': {
      'positionX': null,
      'positionY': 'exponent',
      'column': null,
      'row': null,
      'length': null,
      'area': 'mantissa',
      'colorIntensity': 'Sentiment',
      'colorHue': null,
      'shape': null,
    },
  },
  {
    'id': 361,
    'dataset': {
      'dataset': 'rankingSmall',
      'attribute': 'Sentiment',
      'type': 'ordinal',
      'range': 7,
    },
    'selectedColumns': [
      'mantissa',
      'exponent',
      'Sentiment',
    ],
    'mark': 'point',
    'visualVariables': {
      'positionX': 'mantissa',
      'positionY': null,
      'column': null,
      'row': 'exponent',
      'length': null,
      'area': null,
      'colorIntensity': 'Sentiment',
      'colorHue': null,
      'shape': null,
    },
  },
  {
    'id': 362,
    'dataset': {
      'dataset': 'rankingSmall',
      'attribute': 'Sentiment',
      'type': 'ordinal',
      'range': 7,
    },
    'selectedColumns': [
      'mantissa',
      'exponent',
      'Sentiment',
    ],
    'mark': 'point',
    'visualVariables': {
      'positionX': null,
      'positionY': 'mantissa',
      'column': null,
      'row': 'exponent',
      'length': null,
      'area': null,
      'colorIntensity': 'Sentiment',
      'colorHue': null,
      'shape': null,
    },
  },
  {
    'id': 363,
    'dataset': {
      'dataset': 'rankingSmall',
      'attribute': 'Sentiment',
      'type': 'ordinal',
      'range': 7,
    },
    'selectedColumns': [
      'mantissa',
      'exponent',
      'Sentiment',
    ],
    'mark': 'point',
    'visualVariables': {
      'positionX': null,
      'positionY': null,
      'column': null,
      'row': 'exponent',
      'length': 'mantissa',
      'area': null,
      'colorIntensity': 'Sentiment',
      'colorHue': null,
      'shape': null,
    },
  },
  {
    'id': 364,
    'dataset': {
      'dataset': 'rankingSmall',
      'attribute': 'Sentiment',
      'type': 'ordinal',
      'range': 7,
    },
    'selectedColumns': [
      'mantissa',
      'exponent',
      'Sentiment',
    ],
    'mark': 'point',
    'visualVariables': {
      'positionX': null,
      'positionY': null,
      'column': null,
      'row': 'exponent',
      'length': null,
      'area': 'mantissa',
      'colorIntensity': 'Sentiment',
      'colorHue': null,
      'shape': null,
    },
  },
  {
    'id': 366,
    'dataset': {
      'dataset': 'rankingSmall',
      'attribute': 'Sentiment',
      'type': 'ordinal',
      'range': 7,
    },
    'selectedColumns': [
      'mantissa',
      'exponent',
      'Sentiment',
    ],
    'mark': 'point',
    'visualVariables': {
      'positionX': null,
      'positionY': 'mantissa',
      'column': null,
      'row': null,
      'length': 'exponent',
      'area': null,
      'colorIntensity': 'Sentiment',
      'colorHue': null,
      'shape': null,
    },
  },
  {
    'id': 368,
    'dataset': {
      'dataset': 'rankingSmall',
      'attribute': 'Sentiment',
      'type': 'ordinal',
      'range': 7,
    },
    'selectedColumns': [
      'mantissa',
      'exponent',
      'Sentiment',
    ],
    'mark': 'point',
    'visualVariables': {
      'positionX': null,
      'positionY': 'mantissa',
      'column': null,
      'row': null,
      'length': null,
      'area': 'exponent',
      'colorIntensity': 'Sentiment',
      'colorHue': null,
      'shape': null,
    },
  },
  {
    'id': 369,
    'dataset': {
      'dataset': 'rankingSmall',
      'attribute': 'Sentiment',
      'type': 'ordinal',
      'range': 7,
    },
    'selectedColumns': [
      'mantissa',
      'exponent',
      'Sentiment',
    ],
    'mark': 'line',
    'visualVariables': {
      'positionX': 'Sentiment',
      'positionY': 'exponent+mantissa',
      'column': null,
      'row': null,
      'length': null,
      'area': null,
      'colorIntensity': null,
      'colorHue': null,
      'shape': null,
    },
  },
  {
    'id': 370,
    'dataset': {
      'dataset': 'rankingSmall',
      'attribute': 'Sentiment',
      'type': 'ordinal',
      'range': 7,
    },
    'selectedColumns': [
      'mantissa',
      'exponent',
      'Sentiment',
    ],
    'mark': 'line',
    'visualVariables': {
      'positionX': 'Sentiment',
      'positionY': 'exponent',
      'column': null,
      'row': null,
      'length': 'mantissa',
      'area': null,
      'colorIntensity': null,
      'colorHue': null,
      'shape': null,
    },
  },
  {
    'id': 371,
    'dataset': {
      'dataset': 'rankingSmall',
      'attribute': 'Sentiment',
      'type': 'ordinal',
      'range': 7,
    },
    'selectedColumns': [
      'mantissa',
      'exponent',
      'Sentiment',
    ],
    'mark': 'line',
    'visualVariables': {
      'positionX': 'Sentiment',
      'positionY': 'exponent',
      'column': null,
      'row': null,
      'length': null,
      'area': null,
      'colorIntensity': 'mantissa',
      'colorHue': null,
      'shape': null,
    },
  },
  {
    'id': 373,
    'dataset': {
      'dataset': 'rankingSmall',
      'attribute': 'Sentiment',
      'type': 'ordinal',
      'range': 7,
    },
    'selectedColumns': [
      'mantissa',
      'exponent',
      'Sentiment',
    ],
    'mark': 'line',
    'visualVariables': {
      'positionX': 'Sentiment',
      'positionY': 'mantissa',
      'column': null,
      'row': 'exponent',
      'length': null,
      'area': null,
      'colorIntensity': null,
      'colorHue': null,
      'shape': null,
    },
  },
  {
    'id': 374,
    'dataset': {
      'dataset': 'rankingSmall',
      'attribute': 'Sentiment',
      'type': 'ordinal',
      'range': 7,
    },
    'selectedColumns': [
      'mantissa',
      'exponent',
      'Sentiment',
    ],
    'mark': 'line',
    'visualVariables': {
      'positionX': 'Sentiment',
      'positionY': 'mantissa',
      'column': null,
      'row': null,
      'length': 'exponent',
      'area': null,
      'colorIntensity': null,
      'colorHue': null,
      'shape': null,
    },
  },
  {
    'id': 375,
    'dataset': {
      'dataset': 'rankingSmall',
      'attribute': 'Sentiment',
      'type': 'ordinal',
      'range': 7,
    },
    'selectedColumns': [
      'mantissa',
      'exponent',
      'Sentiment',
    ],
    'mark': 'line',
    'visualVariables': {
      'positionX': 'Sentiment',
      'positionY': 'mantissa',
      'column': null,
      'row': null,
      'length': null,
      'area': null,
      'colorIntensity': 'exponent',
      'colorHue': null,
      'shape': null,
    },
  },
  {
    'id': 383,
    'dataset': {
      'dataset': 'datesSmall',
      'attribute': 'Date',
      'type': 'date',
      'range': 7,
    },
    'selectedColumns': [
      'mantissa',
      'exponent',
      'Date',
    ],
    'mark': 'point',
    'visualVariables': {
      'positionX': 'Date',
      'positionY': 'exponent+mantissa',
      'column': null,
      'row': null,
      'length': null,
      'area': null,
      'colorIntensity': null,
      'colorHue': null,
      'shape': null,
    },
  },
  {
    'id': 384,
    'dataset': {
      'dataset': 'datesSmall',
      'attribute': 'Date',
      'type': 'date',
      'range': 7,
    },
    'selectedColumns': [
      'mantissa',
      'exponent',
      'Date',
    ],
    'mark': 'point',
    'visualVariables': {
      'positionX': 'Date',
      'positionY': 'exponent',
      'column': null,
      'row': null,
      'length': 'mantissa',
      'area': null,
      'colorIntensity': null,
      'colorHue': null,
      'shape': null,
    },
  },
  {
    'id': 385,
    'dataset': {
      'dataset': 'datesSmall',
      'attribute': 'Date',
      'type': 'date',
      'range': 7,
    },
    'selectedColumns': [
      'mantissa',
      'exponent',
      'Date',
    ],
    'mark': 'point',
    'visualVariables': {
      'positionX': 'Date',
      'positionY': 'exponent',
      'column': null,
      'row': null,
      'length': null,
      'area': 'mantissa',
      'colorIntensity': null,
      'colorHue': null,
      'shape': null,
    },
  },
  {
    'id': 386,
    'dataset': {
      'dataset': 'datesSmall',
      'attribute': 'Date',
      'type': 'date',
      'range': 7,
    },
    'selectedColumns': [
      'mantissa',
      'exponent',
      'Date',
    ],
    'mark': 'point',
    'visualVariables': {
      'positionX': 'Date',
      'positionY': 'exponent',
      'column': null,
      'row': null,
      'length': null,
      'area': null,
      'colorIntensity': 'mantissa',
      'colorHue': null,
      'shape': null,
    },
  },
  {
    'id': 391,
    'dataset': {
      'dataset': 'datesSmall',
      'attribute': 'Date',
      'type': 'date',
      'range': 7,
    },
    'selectedColumns': [
      'mantissa',
      'exponent',
      'Date',
    ],
    'mark': 'point',
    'visualVariables': {
      'positionX': 'Date',
      'positionY': 'mantissa',
      'column': null,
      'row': 'exponent',
      'length': null,
      'area': null,
      'colorIntensity': null,
      'colorHue': null,
      'shape': null,
    },
  },
  {
    'id': 392,
    'dataset': {
      'dataset': 'datesSmall',
      'attribute': 'Date',
      'type': 'date',
      'range': 7,
    },
    'selectedColumns': [
      'mantissa',
      'exponent',
      'Date',
    ],
    'mark': 'point',
    'visualVariables': {
      'positionX': 'Date',
      'positionY': null,
      'column': null,
      'row': 'exponent',
      'length': 'mantissa',
      'area': null,
      'colorIntensity': null,
      'colorHue': null,
      'shape': null,
    },
  },
  {
    'id': 393,
    'dataset': {
      'dataset': 'datesSmall',
      'attribute': 'Date',
      'type': 'date',
      'range': 7,
    },
    'selectedColumns': [
      'mantissa',
      'exponent',
      'Date',
    ],
    'mark': 'point',
    'visualVariables': {
      'positionX': 'Date',
      'positionY': null,
      'column': null,
      'row': 'exponent',
      'length': null,
      'area': 'mantissa',
      'colorIntensity': null,
      'colorHue': null,
      'shape': null,
    },
  },
  {
    'id': 394,
    'dataset': {
      'dataset': 'datesSmall',
      'attribute': 'Date',
      'type': 'date',
      'range': 7,
    },
    'selectedColumns': [
      'mantissa',
      'exponent',
      'Date',
    ],
    'mark': 'point',
    'visualVariables': {
      'positionX': 'Date',
      'positionY': null,
      'column': null,
      'row': 'exponent',
      'length': null,
      'area': null,
      'colorIntensity': 'mantissa',
      'colorHue': null,
      'shape': null,
    },
  },
  {
    'id': 395,
    'dataset': {
      'dataset': 'datesSmall',
      'attribute': 'Date',
      'type': 'date',
      'range': 7,
    },
    'selectedColumns': [
      'mantissa',
      'exponent',
      'Date',
    ],
    'mark': 'point',
    'visualVariables': {
      'positionX': 'Date',
      'positionY': 'mantissa',
      'column': null,
      'row': null,
      'length': 'exponent',
      'area': null,
      'colorIntensity': null,
      'colorHue': null,
      'shape': null,
    },
  },
  {
    'id': 396,
    'dataset': {
      'dataset': 'datesSmall',
      'attribute': 'Date',
      'type': 'date',
      'range': 7,
    },
    'selectedColumns': [
      'mantissa',
      'exponent',
      'Date',
    ],
    'mark': 'point',
    'visualVariables': {
      'positionX': 'Date',
      'positionY': null,
      'column': null,
      'row': null,
      'length': 'exponent',
      'area': 'mantissa',
      'colorIntensity': null,
      'colorHue': null,
      'shape': null,
    },
  },
  {
    'id': 397,
    'dataset': {
      'dataset': 'datesSmall',
      'attribute': 'Date',
      'type': 'date',
      'range': 7,
    },
    'selectedColumns': [
      'mantissa',
      'exponent',
      'Date',
    ],
    'mark': 'point',
    'visualVariables': {
      'positionX': 'Date',
      'positionY': null,
      'column': null,
      'row': null,
      'length': 'exponent',
      'area': null,
      'colorIntensity': 'mantissa',
      'colorHue': null,
      'shape': null,
    },
  },
  {
    'id': 398,
    'dataset': {
      'dataset': 'datesSmall',
      'attribute': 'Date',
      'type': 'date',
      'range': 7,
    },
    'selectedColumns': [
      'mantissa',
      'exponent',
      'Date',
    ],
    'mark': 'point',
    'visualVariables': {
      'positionX': 'Date',
      'positionY': 'mantissa',
      'column': null,
      'row': null,
      'length': null,
      'area': 'exponent',
      'colorIntensity': null,
      'colorHue': null,
      'shape': null,
    },
  },
  {
    'id': 399,
    'dataset': {
      'dataset': 'datesSmall',
      'attribute': 'Date',
      'type': 'date',
      'range': 7,
    },
    'selectedColumns': [
      'mantissa',
      'exponent',
      'Date',
    ],
    'mark': 'point',
    'visualVariables': {
      'positionX': 'Date',
      'positionY': null,
      'column': null,
      'row': null,
      'length': 'mantissa',
      'area': 'exponent',
      'colorIntensity': null,
      'colorHue': null,
      'shape': null,
    },
  },
  {
    'id': 400,
    'dataset': {
      'dataset': 'datesSmall',
      'attribute': 'Date',
      'type': 'date',
      'range': 7,
    },
    'selectedColumns': [
      'mantissa',
      'exponent',
      'Date',
    ],
    'mark': 'point',
    'visualVariables': {
      'positionX': 'Date',
      'positionY': null,
      'column': null,
      'row': null,
      'length': null,
      'area': 'exponent',
      'colorIntensity': 'mantissa',
      'colorHue': null,
      'shape': null,
    },
  },
  {
    'id': 401,
    'dataset': {
      'dataset': 'datesSmall',
      'attribute': 'Date',
      'type': 'date',
      'range': 7,
    },
    'selectedColumns': [
      'mantissa',
      'exponent',
      'Date',
    ],
    'mark': 'point',
    'visualVariables': {
      'positionX': 'Date',
      'positionY': 'mantissa',
      'column': null,
      'row': null,
      'length': null,
      'area': null,
      'colorIntensity': 'exponent',
      'colorHue': null,
      'shape': null,
    },
  },
  {
    'id': 402,
    'dataset': {
      'dataset': 'datesSmall',
      'attribute': 'Date',
      'type': 'date',
      'range': 7,
    },
    'selectedColumns': [
      'mantissa',
      'exponent',
      'Date',
    ],
    'mark': 'point',
    'visualVariables': {
      'positionX': 'Date',
      'positionY': null,
      'column': null,
      'row': null,
      'length': 'mantissa',
      'area': null,
      'colorIntensity': 'exponent',
      'colorHue': null,
      'shape': null,
    },
  },
  {
    'id': 403,
    'dataset': {
      'dataset': 'datesSmall',
      'attribute': 'Date',
      'type': 'date',
      'range': 7,
    },
    'selectedColumns': [
      'mantissa',
      'exponent',
      'Date',
    ],
    'mark': 'point',
    'visualVariables': {
      'positionX': 'Date',
      'positionY': null,
      'column': null,
      'row': null,
      'length': null,
      'area': 'mantissa',
      'colorIntensity': 'exponent',
      'colorHue': null,
      'shape': null,
    },
  },
  {
    'id': 435,
    'dataset': {
      'dataset': 'datesSmall',
      'attribute': 'Date',
      'type': 'date',
      'range': 7,
    },
    'selectedColumns': [
      'mantissa',
      'exponent',
      'Date',
    ],
    'mark': 'point',
    'visualVariables': {
      'positionX': 'mantissa',
      'positionY': null,
      'column': 'Date',
      'row': 'exponent',
      'length': null,
      'area': null,
      'colorIntensity': null,
      'colorHue': null,
      'shape': null,
    },
  },
  {
    'id': 436,
    'dataset': {
      'dataset': 'datesSmall',
      'attribute': 'Date',
      'type': 'date',
      'range': 7,
    },
    'selectedColumns': [
      'mantissa',
      'exponent',
      'Date',
    ],
    'mark': 'point',
    'visualVariables': {
      'positionX': null,
      'positionY': 'mantissa',
      'column': 'Date',
      'row': 'exponent',
      'length': null,
      'area': null,
      'colorIntensity': null,
      'colorHue': null,
      'shape': null,
    },
  },
  {
    'id': 437,
    'dataset': {
      'dataset': 'datesSmall',
      'attribute': 'Date',
      'type': 'date',
      'range': 7,
    },
    'selectedColumns': [
      'mantissa',
      'exponent',
      'Date',
    ],
    'mark': 'point',
    'visualVariables': {
      'positionX': null,
      'positionY': null,
      'column': 'Date',
      'row': 'exponent',
      'length': 'mantissa',
      'area': null,
      'colorIntensity': null,
      'colorHue': null,
      'shape': null,
    },
  },
  {
    'id': 438,
    'dataset': {
      'dataset': 'datesSmall',
      'attribute': 'Date',
      'type': 'date',
      'range': 7,
    },
    'selectedColumns': [
      'mantissa',
      'exponent',
      'Date',
    ],
    'mark': 'point',
    'visualVariables': {
      'positionX': null,
      'positionY': null,
      'column': 'Date',
      'row': 'exponent',
      'length': null,
      'area': 'mantissa',
      'colorIntensity': null,
      'colorHue': null,
      'shape': null,
    },
  },
  {
    'id': 439,
    'dataset': {
      'dataset': 'datesSmall',
      'attribute': 'Date',
      'type': 'date',
      'range': 7,
    },
    'selectedColumns': [
      'mantissa',
      'exponent',
      'Date',
    ],
    'mark': 'point',
    'visualVariables': {
      'positionX': null,
      'positionY': null,
      'column': 'Date',
      'row': 'exponent',
      'length': null,
      'area': null,
      'colorIntensity': 'mantissa',
      'colorHue': null,
      'shape': null,
    },
  },
  {
    'id': 479,
    'dataset': {
      'dataset': 'datesSmall',
      'attribute': 'Date',
      'type': 'date',
      'range': 7,
    },
    'selectedColumns': [
      'mantissa',
      'exponent',
      'Date',
    ],
    'mark': 'line',
    'visualVariables': {
      'positionX': 'Date',
      'positionY': 'exponent+mantissa',
      'column': null,
      'row': null,
      'length': null,
      'area': null,
      'colorIntensity': null,
      'colorHue': null,
      'shape': null,
    },
  },
  {
    'id': 480,
    'dataset': {
      'dataset': 'datesSmall',
      'attribute': 'Date',
      'type': 'date',
      'range': 7,
    },
    'selectedColumns': [
      'mantissa',
      'exponent',
      'Date',
    ],
    'mark': 'line',
    'visualVariables': {
      'positionX': 'Date',
      'positionY': 'exponent',
      'column': null,
      'row': null,
      'length': null,
      'area': null,
      'colorIntensity': 'mantissa',
      'colorHue': null,
      'shape': null,
    },
  },
  {
    'id': 482,
    'dataset': {
      'dataset': 'datesSmall',
      'attribute': 'Date',
      'type': 'date',
      'range': 7,
    },
    'selectedColumns': [
      'mantissa',
      'exponent',
      'Date',
    ],
    'mark': 'line',
    'visualVariables': {
      'positionX': 'Date',
      'positionY': 'mantissa',
      'column': null,
      'row': 'exponent',
      'length': null,
      'area': null,
      'colorIntensity': null,
      'colorHue': null,
      'shape': null,
    },
  },
  {
    'id': 483,
    'dataset': {
      'dataset': 'datesSmall',
      'attribute': 'Date',
      'type': 'date',
      'range': 7,
    },
    'selectedColumns': [
      'mantissa',
      'exponent',
      'Date',
    ],
    'mark': 'line',
    'visualVariables': {
      'positionX': 'Date',
      'positionY': 'mantissa',
      'column': null,
      'row': null,
      'length': null,
      'area': null,
      'colorIntensity': 'exponent',
      'colorHue': null,
      'shape': null,
    },
  },
  {
    'id': 491,
    'dataset': {
      'dataset': 'datesSmall',
      'attribute': 'Date',
      'type': 'date',
      'range': 7,
    },
    'selectedColumns': [
      'mantissa',
      'exponent',
      'Date',
    ],
    'mark': 'area-line',
    'visualVariables': {
      'positionX': 'Date',
      'positionY': 'exponent+mantissa',
      'column': null,
      'row': null,
      'length': null,
      'area': null,
      'colorIntensity': null,
      'colorHue': null,
      'shape': null,
    },
  },
  {
    'id': 492,
    'dataset': {
      'dataset': 'datesSmall',
      'attribute': 'Date',
      'type': 'date',
      'range': 7,
    },
    'selectedColumns': [
      'mantissa',
      'exponent',
      'Date',
    ],
    'mark': 'area-line',
    'visualVariables': {
      'positionX': 'Date',
      'positionY': 'exponent',
      'column': null,
      'row': null,
      'length': null,
      'area': null,
      'colorIntensity': 'mantissa',
      'colorHue': null,
      'shape': null,
    },
  },
  {
    'id': 494,
    'dataset': {
      'dataset': 'datesSmall',
      'attribute': 'Date',
      'type': 'date',
      'range': 7,
    },
    'selectedColumns': [
      'mantissa',
      'exponent',
      'Date',
    ],
    'mark': 'area-line',
    'visualVariables': {
      'positionX': 'Date',
      'positionY': 'mantissa',
      'column': null,
      'row': 'exponent',
      'length': null,
      'area': null,
      'colorIntensity': null,
      'colorHue': null,
      'shape': null,
    },
  },
  {
    'id': 495,
    'dataset': {
      'dataset': 'datesSmall',
      'attribute': 'Date',
      'type': 'date',
      'range': 7,
    },
    'selectedColumns': [
      'mantissa',
      'exponent',
      'Date',
    ],
    'mark': 'area-line',
    'visualVariables': {
      'positionX': 'Date',
      'positionY': 'mantissa',
      'column': null,
      'row': null,
      'length': null,
      'area': null,
      'colorIntensity': 'exponent',
      'colorHue': null,
      'shape': null,
    },
  },
];

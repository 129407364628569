/* eslint-disable */
import React, {useEffect, useRef, useState} from 'react';
import * as d3 from 'd3';
import * as Plot from '@observablehq/plot';
import {checkedChannel} from '../utils.js';

const WidthBarLegend = ({data, visualVariables, mark}) => {
  const [error, setError] = useState(Error());
  // Ref to hold the SVG container
  const chartContainer = useRef(null);
  // style variables
  const colorFill = '#d6d8e7';
  const colorStroke = '#1c1e53';
  // dimensions
  const width = 800; //same as plot
  const marginLeft = 100; //same as plot
  const marginRight = 450; //same as plot
  const marginTop = 10; // 5 times smaller than plot
  const marginBottom = 20; // 5 times smaller than plot
  const height = 120; // 5 times smaller than plot
  const chartWidth = width - marginLeft - marginRight;
  const dateData = visualVariables.positionX === 'Date';
  const color = !checkedChannel(visualVariables.colorIntensity) ? (!checkedChannel(visualVariables.colorHue) ? colorFill: visualVariables.colorHue) : visualVariables.colorIntensity;
  const otherLegend = checkedChannel(visualVariables.colorIntensity) || checkedChannel(visualVariables.colorHue) || checkedChannel(visualVariables.symbol)
  const maxLength = Math.trunc(d3.max(data, (d) => d[visualVariables.length]));
  const minLength = Math.trunc(d3.min(data, (d) => d[visualVariables.length]));
  const middle = minLength + (maxLength - minLength)/2;
  const legendData = [{"value":minLength, "name": String(minLength)}, {"value":middle, "name": String( Math.ceil(middle))}, {"value":maxLength, "name":String(maxLength)}]
  const defaultMaxDomain = 100; //for plots without position
  const defaultMaxDomainFacet = 20; //for plots without position

  useEffect(() => {
    try {
      // Function to update the chart when data or visual variables or marks change
      if (data === undefined || visualVariables === undefined) return;
      const chart = updateChart();
      const plot = Plot.plot(chart);
      plot.setAttribute('role', 'main');
      chartContainer.current.append(plot);
      // eslint-disable-next-line consistent-return
      return () => plot.remove(); // remove the previous chart and redraw
    } catch (e) {
      setError(e);
      console.error(e);
    }
  }, [data, visualVariables, mark]);

  // Function to update the Plot chart
  const updateChart = () => {
  
    // Initialize chart with base configuration and directly include conditional properties
    const chart = {
      width,
      height,
      marginLeft,
      marginRight,
      marginTop,
      marginBottom,
      x: {labelOffset:0},
      y: {label:null, domain:[0, 20], tickValues: null},
      marks: drawMarks()
    };
  
    return chart;
  };


  const drawMarks = () => {
    const marks = [];
    // Axis style for nominal data
    marks.push(Plot.axisY({label:null, ticks:0}))
    const lineMarks = linesNominal(marks);
    return lineMarks;
    
  };

  const linesNominal = (marks) => {
    const baseChartConfig = {
      fill: color,
      stroke: colorStroke,
    };
    // Handling for nominal dimensions with bars, lines, or units

      return drawWidth(baseChartConfig, marks);
      
  };

   // draw bars with different width
   const drawWidth = (chart, marks) => {
    // Extract unique values for the length domain
    const lengthDomain = Array.from(new Set(data.map((item) => item[visualVariables.length])));
    
    console.log(lengthDomain)
    // for every value of lengthDomain create an other bar
    // we do this because we can not access data inside inset like we do with x, y, or fill
    // so we create different bars based on the dimension that is choosen for length
    legendData.map((item) => {
      const inset = setInset(item["value"]);
      chart.opacity = (d) => d["value"] === item["value"] ? 1 : 0;
        chart.insetLeft = inset;
        chart.insetRight = inset;
        marks.push(Plot.barY(legendData, {x: (d) => visualVariables.length === 'exponent' ? 10 ** Math.ceil(d["value"]) : Math.ceil(d["value"]), y1:0, y2:10, sort: {x: "-x"}, ...chart}));
    });
    return marks;
  };

    // dynamic manipulation of inset id not supported by Plot
    const setInset = (d) => {
      const positionXValues = legendData.map((item) => Math.ceil(item["value"]));
      console.log('positionXValues', positionXValues)
      // aproximate Width per Band, in practice is smaller as it doesn't take into account the axis and the padding
      const widthBand = chartWidth / positionXValues.length;
      const ratio = (maxLength - d)/maxLength; // ratio between d and max value of the domain
      // to better display width differences we compute the range of domain values
      const range = (maxLength - minLength)/maxLength;
      // range is between 0 and 1, the smaller the range the fewer different values that we visualize
      // we divide by 4, to change the analogy. For examlpe to make 2/4 into 2, 3/4 into 3 etc.
      const inset = widthBand/range/4*ratio;
  
      return inset;
    };


  // Render the SVG container
  return (
    <div ref={chartContainer}></div>   
  );
};

export default WidthBarLegend;

export const columns = {
  "nominal": [
    {
      "key": "mantissa",
      "type": "quantitative",
      "min": 0,
      "max": null,
      "range": null
    },
    {
      "key": "exponent",
      "type": "quantitative",
      "min": 0,
      "max": null,
      "range": null
    },
    {
      "key": "Category",
      "type": "nominal",
      "min": 0,
      "max": null,
      "range": 7
    }
  ],
  "ordinal": [
    {
      "key": "mantissa",
      "type": "quantitative",
      "min": 0,
      "max": null,
      "range": null
    },
    {
      "key": "exponent",
      "type": "quantitative",
      "min": 0,
      "max": null,
      "range": null
    },
    {
      "key": "Sentiment",
      "type": "ordinal",
      "min": 0,
      "max": null,
      "range": 7
    }
  ],
  "quantitative": [
    {
      "key": "mantissa",
      "type": "quantitative",
      "min": 0,
      "max": null,
      "range": null
    },
    {
      "key": "exponent",
      "type": "quantitative",
      "min": 0,
      "max": null,
      "range": null
    },
    {
      "key": "LikesPerComment",
      "type": "quantitative",
      "min": 0,
      "max": null,
      "range": 7
    }
  ],
  "date": [
    {
      "key": "mantissa",
      "type": "quantitative",
      "min": 0,
      "max": null,
      "range": null
    },
    {
      "key": "exponent",
      "type": "quantitative",
      "min": 0,
      "max": null,
      "range": null
    },
    {
      "key": "Date",
      "type": "date",
      "min": 0,
      "max": null,
      "range": 7
    }
  ]
}
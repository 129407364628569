/* eslint-disable */
import React, {useEffect, useRef, useState} from 'react';
import * as d3 from 'd3';
import * as Plot from '@observablehq/plot';
import {checkedChannel} from '../utils.js';

const AreaLegend = ({data, visualVariables, mark}) => {
  const [error, setError] = useState(Error());
  // Ref to hold the SVG container
  const chartContainer = useRef(null);
  // style variables
  const colorFill = '#d6d8e7';
  const colorStroke = '#1c1e53';
  // dimensions
  const width = 800;
  const marginLeft = 500;
  const marginRight = 100;
  const marginTop = 10;
  const marginBottom = 20;
  const height = 100;
  const dateData = visualVariables.positionX === 'Date'|| visualVariables.column === "Date" || visualVariables.row === "Date";
  const color = !checkedChannel(visualVariables.colorIntensity) ? (!checkedChannel(visualVariables.colorHue) ? colorFill: visualVariables.colorHue) : visualVariables.colorIntensity;
  const otherLegend = checkedChannel(visualVariables.colorIntensity) || checkedChannel(visualVariables.colorHue) || checkedChannel(visualVariables.symbol)
  const maxArea = Math.trunc(d3.max(dateData ? data.filter(d=> d["exponent"]>0): data, (d) => d[visualVariables.area]));
  const minArea = Math.trunc(d3.min(dateData ? data.filter(d=> d["exponent"]>0): data, (d) => d[visualVariables.area]));
  const middle = minArea + (maxArea - minArea)/2
  const legendData = [{"value":minArea}, {"value":middle}, {"value":maxArea}]

  useEffect(() => {
    try {
      // Function to update the chart when data or visual variables or marks change
      if (data === undefined || visualVariables === undefined) return;
      const chart = updateChart();
      const plot = Plot.plot(chart);
      plot.setAttribute('role', 'main');
      chartContainer.current.append(plot);
      // eslint-disable-next-line consistent-return
      return () => plot.remove(); // remove the previous chart and redraw
    } catch (e) {
      setError(e);
      console.error(e);
    }
  }, [data, visualVariables, mark]);

  // Function to update the Plot chart
  const updateChart = () => {
  
    // Initialize chart with base configuration and directly include conditional properties
    const chart = {
      width,
      height,
      marginLeft,
      marginRight,
      marginTop,
      marginBottom,
      x: { labelOffset:0,},
      y: {label:null},
      r: drawRadius(visualVariables.area),
      marks: drawMarks()
    };
  
    return chart;
  };


  const drawMarks = () => {
    const marks = [];
    // Axis style for nominal data

    // if mark is point
    if (mark === 'point') {
      const pointMarks = drawPoints(marks);
      return pointMarks;
    }
    // if mark is encoded as line
    if (mark === 'line') {
      // const lineMarks = drawMarkLine(marks, 'minDomain', 'position');
      // return lineMarks;
    }
    // if mark is encoded as area-line
    
    return marks;
  };

  const drawPoints = (marksArray) => {
    
    // legend for area
    if(checkedChannel(visualVariables.area) && !checkedChannel(visualVariables.length)){
      marksArray.push(
            Plot.axisX({dy: -22, ticks: visualVariables.area === "mantissa" ? [1,5,9] : [minArea, middle, maxArea], tickSize:12, label:null, tickFormat: d => visualVariables.area === 'exponent' ? 10 ** Math.ceil(d) : Math.ceil(d),}),
            Plot.text([visualVariables.area],{y:0, x:minArea - 1, textAnchor: "end", fontSize:12, fontWeight:'bolder'}),
            Plot.ruleY(legendData, {y:0, stroke:colorFill, strokeWidth:4, strokeOpacity: 0.2}),
            Plot.dot(legendData, {x: "value", r: "value", fill: colorFill, stroke:colorStroke}),
            Plot.axisY({label:null, ticks:0}),
           
      )
    }
  
    return marksArray;
  };


  const drawRadius = (area) => {
    const maxR = otherLegend ? 17 : 20;
    if (!checkedChannel(area)) return {range: [10, 10]};
    return {domain: [minArea, maxArea], range: [4, maxR]};
  };




  // Render the SVG container
  return (
    <div className={(visualVariables.colorIntensity || visualVariables.length) && "area-legend"} ref={chartContainer}></div>   
  );
};

export default AreaLegend;

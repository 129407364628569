/* eslint-disable */
import React, {useEffect, useRef, useState} from 'react';
import * as d3 from 'd3';
import * as Plot from '@observablehq/plot';
import {checkedChannel} from '../utils.js';

const WidthLegend = ({data, visualVariables, mark}) => {
  const [error, setError] = useState(Error());
  // Ref to hold the SVG container
  const chartContainer = useRef(null);
  // style variables
  const colorFill = '#d6d8e7';
  const colorStroke = '#1c1e53';
  // dimensions
  const width = 800; //same as plot
  const marginLeft = 500; //same as plot
  const marginRight = 100; //same as plot
  const marginTop = 10; // 5 times smaller than plot
  const marginBottom = 20; // 5 times smaller than plot
  const height = 120; // 5 times smaller than plot
  const dateData = visualVariables.positionX === 'Date'|| visualVariables.column === "Date" || visualVariables.row === "Date";
  const color = !checkedChannel(visualVariables.colorIntensity) ? (!checkedChannel(visualVariables.colorHue) ? colorFill: visualVariables.colorHue) : visualVariables.colorIntensity;
  const otherLegend = checkedChannel(visualVariables.colorIntensity) || checkedChannel(visualVariables.colorHue) || checkedChannel(visualVariables.symbol)
  const maxLength = Math.trunc(d3.max(dateData ? data.filter(d=> d["exponent"]>0): data, (d) => d[visualVariables.area]));
  const minLength = Math.trunc(d3.min(dateData ? data.filter(d=> d["exponent"]>0): data, (d) => d[visualVariables.area]));
  const middle = minLength + (maxLength - minLength)/2;
  const defaultMaxDomain = 100; //for plots without position
  const defaultMaxDomainFacet = 20; //for plots without position

  useEffect(() => {
    try {
      // Function to update the chart when data or visual variables or marks change
      if (data === undefined || visualVariables === undefined) return;
      const chart = updateChart();
      const plot = Plot.plot(chart);
      plot.setAttribute('role', 'main');
      chartContainer.current.append(plot);
      // eslint-disable-next-line consistent-return
      return () => plot.remove(); // remove the previous chart and redraw
    } catch (e) {
      setError(e);
      console.error(e);
    }
  }, [data, visualVariables, mark]);

  // Function to update the Plot chart
  const updateChart = () => {
  
    // Initialize chart with base configuration and directly include conditional properties
    const chart = {
      width,
      height,
      marginLeft,
      marginRight,
      marginTop,
      marginBottom,
      x: { labelOffset:0, domain:[0, 20]},
      y: {label:null, domain:[0, 20]},
      marks: drawMarks()
    };
  
    return chart;
  };


  const drawMarks = () => {
    const marks = [];
    // Axis style for nominal data

    // if mark is point
    if (mark === 'point') {
      const pointMarks = drawPoints(marks);
      return pointMarks;
    }
    // if mark is encoded as line
    if (mark === 'line') {
      // const lineMarks = drawMarkLine(marks, 'minDomain', 'position');
      // return lineMarks;
    }
    // if mark is encoded as area-line
    
    return marks;
  };

  const drawPoints = (marksArray) => {
    
    // legend for area
    if(checkedChannel(visualVariables.area)){
      //get a dataset with the values for the area
      const chartConfig = {
        stroke: color,
        strokeWidth: d=> getRuleStroke(d)
      };
      
      const legendData = [{"value":minLength, "name": String(minLength), "order": 0}, {"value":middle, "name":String(middle), "order": 1}, {"value":maxLength, "name":String(maxLength), "order": 2}]
      marksArray.push(
            Plot.axisX({label:null, ticks:0}),
            Plot.text([visualVariables.area],{y:5, x:minLength - 2, textAnchor: "end", fontSize:12, fontWeight:'bolder'}),
            Plot.axisY({label:null, ticks:0}),
      )
    
      if (((checkedChannel(visualVariables.positionX) || checkedChannel(visualVariables.column))) || (checkedChannel(visualVariables.row) && checkedChannel(visualVariables.column))) {
        marksArray.push(
          Plot.ruleX(legendData, { y1: 5, y2:10, x:  d => checkForJitter(d, 'y'), strokeWidth: d=> getRuleStroke(d) + 2, stroke: colorStroke, }),
          Plot.ruleX(legendData, { y1: 5, y2:10, x:  d => checkForJitter(d, 'y'), ...chartConfig }),
          Plot.text(legendData,{text: (d) => visualVariables.area === 'exponent' ? 10 ** Math.ceil(d["value"]) : Math.ceil(d["value"]), dy:30, x: d =>checkForJitter(d, 'y'), textAnchor: "middle", fontSize:10, fontWeight:'light'}),
        );
      } else {
        marksArray.push(
          Plot.ruleY(legendData, { x1: d => checkForJitter(d, 'y'), x2:d => checkForJitter(d, 'y') + 2, y: 5, strokeWidth: d=>getRuleStroke(d) + 2, stroke: colorStroke, }),
          Plot.ruleY(legendData, { x1: d => checkForJitter(d, 'y'), x2:d => checkForJitter(d, 'y') + 2, y: 5, ...chartConfig }),
          Plot.text(legendData,{text: (d) => visualVariables.area === 'exponent' ? 10 ** Math.ceil(d["value"]) : Math.ceil(d["value"]), dy:48, x: d =>checkForJitter(d, 'y') + 1, textAnchor: "middle", fontSize:10, fontWeight:'light'}),
        );
      }
    
    }
  
    return marksArray;
  };

  const getRuleStroke = (d) => {
    return visualVariables.area ? d["value"] * 4 : 5;
  }

  const checkForJitter = (d, axis) => {
    //find how many rows in the dataset contains the same value
    const gap=5;
    if(axis==="x"){
      if(d["value"] === minLength){
        return gap;
      } else if(d["value"] === middle){
        return gap + minLength + gap;
      } else {
        return gap + minLength + gap + middle + gap;
      }
    } else {
      return minLength + (d["order"]) * (gap+3);
    }
    
  }

    //To get domain for plots without position
    const getDefaultMaxDomain = (axis) => {
      if(visualVariables.positionY === null && visualVariables.positionX === null){
        if(checkedChannel(visualVariables.row)){
          if(axis === 'y'){
            return defaultMaxDomainFacet;
          } else {
            return defaultMaxDomain;
          }
        }
        if(checkedChannel(visualVariables.column)){
          if(axis === 'x'){
            return defaultMaxDomainFacet;
          } else {
            return defaultMaxDomain;
          }
        }
      } else if((visualVariables.positionY === null && checkedChannel(visualVariables.row))){
          if(axis = 'x') return defaultMaxDomain;
          if(axis = 'y') return defaultMaxDomainFacet;
      } else if(visualVariables.positionX === null && checkedChannel(visualVariables.column)){
          if(axis = 'x') return defaultMaxDomainFacet;
          if(axis = 'y') return defaultMaxDomain;
      } else {
        return defaultMaxDomain;
      }
    }



  // Render the SVG container
  return (
    <div className={"area-legend"} ref={chartContainer}></div>   
  );
};

export default WidthLegend;

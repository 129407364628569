
// Names,categories and types of visual channels, based on the literature
export const channels = {
  positionX: {name: 'Position X', category: 'Spatial position', type: ['quantitative', 'nominal', 'ordinal', 'date'], range: 'large', bothMantExp: true},
  positionY: {name: 'Position Y', category: 'Spatial position', type: ['quantitative', 'nominal', 'ordinal', 'date'], range: 'large', bothMantExp: true},
  column: {name: 'Column', category: 'Spatial position', type: ['quantitative', 'nominal', 'ordinal', 'date'], range: 'small', bothMantExp: false},
  row: {name: 'Row', category: 'Spatial position', type: ['quantitative', 'nominal', 'ordinal', 'date'], range: 'small', bothMantExp: false},
  length: {name: 'Length', category: 'Spatial size', type: ['quantitative'], range: 'large', bothMantExp: false},
  area: {name: 'Area', category: 'Spatial size', type: ['quantitative'], range: 'large', bothMantExp: false},
  colorIntensity: {name: 'Color Intensity', category: 'Color Intensity', type: ['quantitative', 'ordinal'], range: 'small', bothMantExp: false},
  colorHue: {name: 'Color Hue', category: 'Identity channels', type: ['nominal'], range: 'large', bothMantExp: false},
  shape: {name: 'Shape', category: 'Identity channels', type: ['nominal'], range: 'small', bothMantExp: false},
};


const colorPalettes = {colorIntensity: 'RdPu', colorHue: 'Tableau10'};


// Find and return the data type of a selected column
export const returnDataType = (key, columns) => {
  const foundObject = columns.find((obj) => obj.key === key);
  return foundObject ? foundObject.type : 'Not Found';
};


export const getColor = (channel) => {
  if (channel.colorIntensity) {
    return colorPalettes['colorIntensity'];
  } else if (channel.colorHue) {
    return colorPalettes['colorHue'];
  } else {
    return 'black';
  }
};


export const checkedChannel = (value) => {
  return !(value === undefined || value === null || value ==='');// (value === null || value ==='');
};


// Find and return the domain of a dimension based on data values
export const returnDomainValuesBandScale = (dim, data) => {
  let domain = [];
  const values = data.map((item) => item[dim]);
  if (dim === 'exponent') {
    const exponentArray = [];
    // change domain based on digits
    values.map((v) => exponentArray.push(v));
    domain = [...exponentArray].sort((a, b) => a - b); ;
  } else {
    const mantissaArray = [];
    values.map((v) => mantissaArray.push(Math.floor(v)));
    domain = [...mantissaArray].sort((a, b) => a - b); ;
  }

  return domain;
};




